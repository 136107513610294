import React, { Component } from 'react'
import Navigasi from "../../Navigate/Navigasi"
import notFound from "../../../assets/img/profile-icon.png"
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import classnames from 'classnames'
import {Button, Modal, ModalBody} from 'reactstrap'
import axios from 'axios'
import { isEmpty } from 'lodash'
import Loading from '../../loading'
import UploadFile from './UploadFile'
import baseurl from '../../../helpers/constants'

class AsuransiPolisProperty extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '2',
      modal: false,
      dataPolis: [],
      benefit: [],
      Endorsement: [],
      loader1: false,
      loader2: false
    }
    this.toggle = this.toggle.bind(this)
    this.toggleB = this.toggleB.bind(this)
    this.convertToRupiah = this.convertToRupiah.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  toggleB() {
    this.setState({
      modal: !this.state.modal
    })
  }

  convertToRupiah(val) {
    const numberString = val.toString()
    const sisa = numberString.length % 3
    let rupiah = numberString.substr(0, sisa)
    const ribuan = numberString.substr(sisa).match(/\d{3}/g)
    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }
    return `Rp ${rupiah}`
  }

  componentDidMount() {
    const {
      match
    } = this.props

    const getSolution = match.path.split('/')
    console.log('asd', getSolution)
    let solution = ''
    switch (getSolution[1]) {
    case 'personal':
      solution = 1
      break
    case 'vehicle':
      solution = 2
      break
    case 'property':
      solution = 3
      break
    case 'travel':
      solution = 4
      break
    }
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    axios.get(`${baseurl.API}/v1/vendor/detail_policy_user/${solution}/` + this.props.match.params.ids)
      .then(res => {
        console.log(res)
        const dataPolis = res.data.data
        this.setState({
          loader1: true,
          dataPolis: dataPolis,
          benefit: dataPolis.benefit_premia
        })
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`${baseurl.API}/v1/endorsement_status/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader2: true,
          Endorsement: dataPolis
        })
      })
      .catch(err => {
        console.log(err)
      })

  }

  render() {
    const {
      dataPolis,
      loader1
    } = this.state

    if(!(loader1 && loader1)){
      return (
        <div>
          <Navigasi />
          <div className="profil-user">
            <div className="container">
              <Nav tabs>
                {/* <NavItem>
			                <Link
													to = {"/property/polis/"+ this.props.match.params.id} //+ this.match.params.id}
			                    className="nav-link"
			                    onClick={() => { this.toggle('1') }}
			                >
			                    <button>Profil</button>
			                </Link>
			            </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2') }}
                  >
                    <button>Asuransi</button>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <h3>Anna Lorignhton</h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <h4>Tempat Lahir</h4>
                          <p>Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Tanggal Lahir</h4>
                          <p>3 Januari 1986</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Jenis Kelamin</h4>
                          <p>Wanita</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Pekerjaan</h4>
                          <p>Karyawan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor NPWP</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <h4>Alamat Lengkap</h4>
                          <p>Jl.H.R. Rasuna Said XV</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Provinsi</h4>
                          <p>DKI Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kota</h4>
                          <p>Jakarta Selatan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kode Pos</h4>
                          <p>12240</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer Telepon Rumah</h4>
                          <p>0217228899</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer HP</h4>
                          <p>08189992222</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Email</h4>
                          <p>anna@gmail.com</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Loading />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Navigasi />
        <div className="profil-user">
          <div className="container">
            <Nav tabs>
              {/* <NavItem>
                  <Link
                      to = {"/property/polis/"+ this.props.match.params.id} //+ this.match.params.id}
                      className="nav-link"
                      onClick={() => { this.toggle('1') }}
                  >
                      <button>Profil</button>
                  </Link>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2') }}
                >
                  <button>Asuransi</button>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="photo-polis">
                      {
                        dataPolis.publisher_logo ?
                          (
                            <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                          ) : (
                            <img className="image-profile" src={notFound} alt="" />
                          )
                      }
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <h3>Anna Lorignhton</h3>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <h4>Tempat Lahir</h4>
                        <p>Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Tanggal Lahir</h4>
                        <p>3 Januari 1986</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Jenis Kelamin</h4>
                        <p>Wanita</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Pekerjaan</h4>
                        <p>Karyawan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor NPWP</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-12">
                        <h4>Alamat Lengkap</h4>
                        <p>Jl.H.R. Rasuna Said XV</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Provinsi</h4>
                        <p>DKI Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kota</h4>
                        <p>Jakarta Selatan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kode Pos</h4>
                        <p>12240</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer Telepon Rumah</h4>
                        <p>0217228899</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer HP</h4>
                        <p>08189992222</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Email</h4>
                        <p>anna@gmail.com</p>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="asuransi-info">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="row">
                        <div className="informasi">
                          <div className="informasiDiri">
                            <div className="judulPolis">
                              <h3>Informasi Data Diri</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Nama Lengkap</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.full_name : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Tempat Lahir</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.birth_place : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Tanggal Lahir</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.birth_date : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Jenis Kelamin</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.gender : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Status Pernikahan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.marriage_status : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.identity_number : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Nomor NPWP</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.npwp_number : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Pekerjaan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.jobs : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Sumber Pendapatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.source_income : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Jumlah Pendapatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.total_salary : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Alamat Lengkap Sesuai KTP</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.address_detail : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Provinsi</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.province : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kota</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.city : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kecamatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.district : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kelurahan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.village : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kode Pos</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.postal_code : '-'}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Email</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.email : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Nomor Telepon</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.phone_number : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Nomor Handphone</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.mobile_number : '-'}</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="dataKendaraan">
                            <div className="judulPolis">
                              <h3>Informasi Property</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Nama Lengkap</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.property.owners_name ? dataPolis.property.owners_name : dataPolis.profile.full_name}</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  {dataPolis.property.name && dataPolis.property.property_type == 'Villa'?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nama Villa</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.name}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.type_of_business ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Jenis Usaha</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.type_of_business}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.name && dataPolis.property.property_type == 'Tempat Usaha'?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Dengan Nama</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.name}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.building_area ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Luas Bangunan (m2)</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.building_area}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.building_height ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Jumlah Lantai</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.building_height}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.basement ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Basement</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.basement}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.tower_name ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nama Tower</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.tower_name}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.number_of_floor ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Berada Di Lantai</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.number_of_floor}</p>
                                      </div>
                                    </div>) : ''
                                  }

                                  {dataPolis.property.building_value ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nilai Bangunan (Rp)</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{this.convertToRupiah(dataPolis.property.building_value)}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.value_of_building_content ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nilai Isi Bangunan (Rp)</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{this.convertToRupiah(dataPolis.property.value_of_building_content)}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.stock_value ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nilai Stok (Rp)</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{this.convertToRupiah(dataPolis.property.stock_value)}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.engine_value ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Nilai Mesin</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{this.convertToRupiah(dataPolis.property.engine_value)}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.building_quality ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Kualitas Bangunan</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.building_quality}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.ever_claimed_in_12_months ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Periode Klaim Dalam 12 Bulan</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.ever_claimed_in_12_months}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.ever_flooded ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Pernah Banjir Dalam 5 Tahun Terakhir</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.ever_flooded}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                  {dataPolis.property.have_content_more_than_100_million ?
                                    (<div className="col-md-4">
                                      <div className="judul-box">
                                        <h4>Memiliki barang berharga yang bernilai lebih dari 100 juta rupiah</h4>
                                      </div>
                                      <div className="data-box">
                                        <p>{dataPolis.property.have_content_more_than_100_million}</p>
                                      </div>
                                    </div>) : ''
                                  }
                                </div>
                              </div>
                              {dataPolis.property.unit_number ?
                                (<div className="col-md-3">
                                  <div className="judul-box">
                                    <h4>Nomor Unit</h4>
                                  </div>
                                  <div className="data-box">
                                    <p>{dataPolis.property.unit_number}</p>
                                  </div>
                                  <div className="simpan simpanxlProperty">
                                    <a href={`${baseurl.API}/v1/vendor/download_policy_as_excel/3/${this.props.match.params.ids}/xlsx`} className="back-info">Simpan .xls</a>
                                  </div>
                                  <div className="simpan simpanProperty">
                                    <a href={`${baseurl.API}/v1/vendor/download_policy_user/3/${this.props.match.params.ids}`} className="back-info">Simpan .pdf</a>
                                  </div>
                                </div>) : (
                                  <div className="col-md-3">
                                    <div className="simpan simpanxlProperty">
                                      <a href={`${baseurl.API}/v1/vendor/download_policy_as_excel/3/${this.props.match.params.ids}/xlsx`} className="back-info">Simpan .xls</a>
                                    </div>
                                    <div className="simpan simpanProperty">
                                      <a href={`${baseurl.API}/v1/vendor/download_policy_user/3/${this.props.match.params.ids}`} className="back-info">Simpan .pdf</a>
                                    </div>
                                  </div>
                                )
                              }

                            </div>
                            <hr />
                          </div>

                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Nama Produk</h4>
                          <p>{this.state.dataPolis.insurance_product}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Masa Berlaku</h4>
                          <p>{this.state.dataPolis.validity_period}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Jenis Perlindungan</h4>
                          <p>{this.state.dataPolis.packages ? this.state.dataPolis.packages[0].title_package : '-'}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Nomor Polis</h4>
                          <p>{this.state.dataPolis.policy_number ? this.state.dataPolis.policy_number : '-'}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Status Polis</h4>
                          <p>{this.state.dataPolis.status == 1 ? "Aktif" : "Tidak Aktif"}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Total Premi</h4>
                          <p className="redFont">{this.state.dataPolis.grand_total ? this.convertToRupiah(parseInt(dataPolis.grand_total)) : "-"}</p>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="asuransi col-md-6">
                              <h4>Perluasan Cover</h4>
                              {
                                !isEmpty(dataPolis.packages) ? 
                                  dataPolis.packages.map((data, key) => (
                                    <Row key={key}>
                                      <Col md="6">
                                        <span>{data.title_package}</span>
                                      </Col>
                                      <Col md="6">
                                        <span className="price-info">
                                          {this.convertToRupiah(data.price)}
                                        </span>
                                      </Col>
                                    </Row>
                                  )) : '-'
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="asuransi">
                        <h4>Manfaat Produk</h4>
                        <ol>
                          {this.state.benefit.map((data, key) => {
                            return <li key={key}>{data.benefit_title}</li>
                          })}
                        </ol>
                      </div>
                      <div className="upload">
                        <Button onClick={this.toggleB}>Upload Polis</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleB} size="700px" className="modal-upload">
                          <ModalBody>
                            <div className="upload">
                              <div className="container">
                                <div className="row">
                                  <div className="col-sm-8">

                                    <UploadFile url={this.props.match.params.ids} number={this.state.dataPolis.policy_number}/>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                      <div className="status">
                        <h5>Endorsement Status</h5>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nomor</th>
                              <th>Keterangan</th>
                              <th>File</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.Endorsement.length >= 1 ? this.state.Endorsement.map((data, key) => {
                              let str = data.file_name
                              return <tr key={key}>
                                <td>{this.state.dataPolis.policy_number}</td>
                                <td>{data.description}</td>
                                <td><a href={`${data.file_path}`}>{str}</a></td>
                              </tr>
                            }) : <tr><td colSpan="3" className="redFont text-center">Data Endorsement Kosong, Silahkan Upload Polis.</td></tr>}
                            {/* <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}
export default AsuransiPolisProperty
