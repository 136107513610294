import React, { Component } from 'react'
import Navigasi from "../../Navigate/Navigasi"
import notFound from "../../../assets/img/profile-icon.png"
import * as _ from 'lodash'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import {Button, Modal, ModalBody} from 'reactstrap'
import axios from 'axios'
import Loading from '../../loading'
import UploadFile from './UploadFile'
import baseurl from '../../../helpers/constants'

class AsuransiPolisTravel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '2',
      modal: false,
      dataPolis: [],
      benefit: [],
      Endorsement: [],
      loader1: false,
      loader2: false
    }
    this.toggle = this.toggle.bind(this)
    this.toggleB = this.toggleB.bind(this)
    this.download = this.download.bind(this)
    this.convertToRupiah = this.convertToRupiah.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
	
  download() {
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    axios.get(`${baseurl.API}/v1/vendor/download_policy_as_excel/2/${this.props.match.params.ids}/xlsx`).then(() => {
    }).catch(() => {
    })
  }

  toggleB() {
    this.setState({
      modal: !this.state.modal
    })
  }

  convertToRupiah(val) {
    const numberString = val.toString()
    const sisa = numberString.length % 3
    let rupiah = numberString.substr(0, sisa)
    const ribuan = numberString.substr(sisa).match(/\d{3}/g)
    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }
    return `Rp ${rupiah}`
  }

  componentDidMount() {
    const {
      match
    } = this.props

    const getSolution = match.path.split('/')
    console.log('asd', getSolution)
    let solution = ''
    switch (getSolution[1]) {
    case 'personal':
      solution = 1
      break
    case 'vehicle':
      solution = 2
      break
    case 'property':
      solution = 3
      break
    case 'travel':
      solution = 4
      break
    }
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    axios.get(`${baseurl.API}/v1/vendor/detail_policy_user/${solution}/` + this.props.match.params.ids)
      .then(res => {
        console.log(res)
        const dataPolis = res.data.data
        this.setState({
          loader1: true,
          dataPolis: dataPolis,
          benefit: dataPolis.benefit_premia
        })
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`${baseurl.API}/v1/endorsement_status/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader2: true,
          Endorsement: dataPolis
        })
      })
      .catch(err => {
        console.log(err)
      })

  }

  render() {
    const {
      dataPolis,
      loader1
    } = this.state
    console.log('endorsement_status', dataPolis)

    let participantIndividu = dataPolis.travel ?	_.filter(dataPolis.travel.participant, x => x.category === 'dewasa') : []
    let participantDewasa = dataPolis.travel ?	_.filter(dataPolis.travel.participant, x => x.category === 'dewasa') : []
    let participantAnak = dataPolis.travel ?	_.filter(dataPolis.travel.participant, x => x.category === 'anak') : []
    let ahliWaris = dataPolis.travel ?	dataPolis.travel.participant[0].heirs : []

    let pesertaDewasaIndividu = participantIndividu ? participantIndividu.map((key, index) => {
      if (key.category === 'dewasa') {
        return (
          <div>
            <div className="subJudul">
              <h3>Peserta Dewasa {index + 1}</h3>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="judul-box">
                  <h4>Nama Lengkap</h4>
                </div>
                <div className="data-box">
                  <p>{key.full_name}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="judul-box">
                  <h4>Hubungan</h4>
                </div>
                <div className="data-box">
                  <p>{key.relation}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="judul-box">
                  <h4>Tanggal Lahir</h4>
                </div>
                <div className="data-box">
                  <p>{key.bod}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="judul-box">
                  <h4>No Passpor/KTP</h4>
                </div>
                <div className="data-box">
                  <p>{key.identity}</p>
                </div>
              </div>
            </div>
            { key.heirs ? 
              key.heirs.map((data, id) => {
                return (
                  <div key={id}>
                    <div className="subJudul">
                      <h3>Ahli Waris Peserta Dewasa {index + 1}</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="judul-box">
                          <h4>Nama Lengkap</h4>
                        </div>
                        <div className="data-box">
                          <p>{data.full_name}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judul-box">
                          <h4>Hubungan</h4>
                        </div>
                        <div className="data-box">
                          <p>{data.relation}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judul-box">
                          <h4>Tanggal Lahir</h4>
                        </div>
                        <div className="data-box">
                          <p>{data.bod}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="judul-box">
                          <h4>No Passpor/KTP</h4>
                        </div>
                        <div className="data-box">
                          <p>{data.identity}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : ''
            }
          </div>
        )
      }
    }) : ''

    let pesertaDewasaFamily = dataPolis.travel ? participantDewasa.map((key, index) => {
      return (
        <div key={index}>
          <div className="subJudul">
            <h3>Peserta Dewasa {index + 1}</h3>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Nama Lengkap</h4>
              </div>
              <div className="data-box">
                <p>{key.full_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Hubungan</h4>
              </div>
              <div className="data-box">
                <p>{key.relation}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Tanggal Lahir</h4>
              </div>
              <div className="data-box">
                <p>{key.bod}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>No Passpor/KTP</h4>
              </div>
              <div className="data-box">
                <p>{key.identity}</p>
              </div>
            </div>
          </div>
        </div>
      )}) : ''
    let pesertaAnakFamily = dataPolis.travel ? participantAnak.map((key, index) => {
      return (
        <div key={index}>
          <div className="subJudul">
            <h3>Peserta Anak {index + 1}</h3>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Nama Lengkap</h4>
              </div>
              <div className="data-box">
                <p>{key.full_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Hubungan</h4>
              </div>
              <div className="data-box">
                <p>{key.relation}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Tanggal Lahir</h4>
              </div>
              <div className="data-box">
                <p>{key.bod}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>No Passpor/KTP</h4>
              </div>
              <div className="data-box">
                <p>{key.identity}</p>
              </div>
            </div>
          </div>
        </div>
      )}) : ''
    let pesertaAhliWarisFamily = dataPolis.travel && dataPolis.travel.protection_type == 'Family'? ahliWaris.map((key, index) => {
      return (
        <div key={index}>
          <div className="subJudul">
            <h3>Ahli Waris {index + 1}</h3>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Nama Lengkap</h4>
              </div>
              <div className="data-box">
                <p>{key.full_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Hubungan</h4>
              </div>
              <div className="data-box">
                <p>{key.relation}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>Tanggal Lahir</h4>
              </div>
              <div className="data-box">
                <p>{key.bod ? key.bod : ''}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="judul-box">
                <h4>No Passpor/KTP</h4>
              </div>
              <div className="data-box">
                <p>{key.identity}</p>
              </div>
            </div>
          </div>
        </div>
      )}) : ''
    if(!(loader1 && loader1)){
      return (
        <div>
          <Navigasi />
          <div className="profil-user">
            <div className="container">
              <Nav tabs>
                {/* <NavItem>
                    <Link
                        to = {"/travel/polis/"+ this.props.match.params.id} //+ this.match.params.id}
                        className="nav-link"
                        onClick={() => { this.toggle('1') }}
                    >
                        <button>Profil</button>
                    </Link>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2') }}
                  >
                    <button>Asuransi</button>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <h3>Anna Lorignhton</h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <h4>Tempat Lahir</h4>
                          <p>Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Tanggal Lahir</h4>
                          <p>3 Januari 1986</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Jenis Kelamin</h4>
                          <p>Wanita</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Pekerjaan</h4>
                          <p>Karyawan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor NPWP</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <h4>Alamat Lengkap</h4>
                          <p>Jl.H.R. Rasuna Said XV</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Provinsi</h4>
                          <p>DKI Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kota</h4>
                          <p>Jakarta Selatan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kode Pos</h4>
                          <p>12240</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer Telepon Rumah</h4>
                          <p>0217228899</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer HP</h4>
                          <p>08189992222</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Email</h4>
                          <p>anna@gmail.com</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Loading />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Navigasi />
        <div className="profil-user">
          <div className="container">
            <Nav tabs>
              {/* <NavItem>
                <Link
                    to = {"/travel/polis/"+ this.props.match.params.id} //+ this.match.params.id}
                    className="nav-link"
                    onClick={() => { this.toggle('1') }}
                >
                    <button>Profil</button>
                </Link>
            </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2') }}
                >
                  <button>Asuransi</button>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="photo-polis">
                      {
                        dataPolis.publisher_logo ?
                          (
                            <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                          ) : (
                            <img className="image-profile" src={notFound} alt="" />
                          )
                      }
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <h3>Anna Lorignhton</h3>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <h4>Tempat Lahir</h4>
                        <p>Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Tanggal Lahir</h4>
                        <p>3 Januari 1986</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Jenis Kelamin</h4>
                        <p>Wanita</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Pekerjaan</h4>
                        <p>Karyawan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor NPWP</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-12">
                        <h4>Alamat Lengkap</h4>
                        <p>Jl.H.R. Rasuna Said XV</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Provinsi</h4>
                        <p>DKI Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kota</h4>
                        <p>Jakarta Selatan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kode Pos</h4>
                        <p>12240</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer Telepon Rumah</h4>
                        <p>0217228899</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer HP</h4>
                        <p>08189992222</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Email</h4>
                        <p>anna@gmail.com</p>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="asuransi-info">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="row">
                        <div className="informasi">
                          <div className="informasiDiri">
                            <div className="judulPolis">
                              <h3>Informasi Data Diri</h3>
                            </div>
                            <div className="row">
                              {/* <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Nama Lengkap</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.full_name : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Tempat Lahir</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.birth_place : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Tanggal Lahir</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.birth_date : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Jenis Kelamin</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.gender : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Status Pernikahan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.marriage_status : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.identity_number : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Nomor NPWP</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.npwp_number : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Pekerjaan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.jobs : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Sumber Pendapatan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.source_income : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Jumlah Pendapatan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.total_salary : '-'}</p>
                                    </div>
                                  </div> */}
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Alamat Lengkap Sesuai KTP</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.address_detail : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Provinsi</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.province : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kota</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.city : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kecamatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.district : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kelurahan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.village : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kode Pos</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.postal_code : '-'}</p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Email</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.email : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Nomor Telepon</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.phone_number : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="judul-box">
                                      <h4>Nomor Handphone</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.profile ? dataPolis.profile.mobile_number : '-'}</p>
                                    </div>
                                  </div>
                                </div> */}
                            <hr />
                          </div>
                          <div className="dataKendaraan">
                            <div className="judulPolis">
                              <h3>Informasi Peserta Polis </h3>
                            </div>
                            <div className="row">
                              <div className="col-md-9">
                                {
                                  dataPolis.protection_type === 'Individu' ?  pesertaDewasaIndividu : ''
                                }
                                {
                                  dataPolis.protection_type === 'Family' ? pesertaDewasaFamily : ''
                                }
                                {
                                  dataPolis.protection_type === 'Family' ? pesertaAnakFamily : ''
                                }
                                {
                                  dataPolis.protection_type === 'Family' ? pesertaAhliWarisFamily : ''
                                }
                              </div>
                              <div className="col-md-3">
                                <div className="simpan simpanTravel excel ">
                                  <a href={`${baseurl.API}/v1/vendor/download_policy_as_excel/4/${this.props.match.params.ids}/xlsx`} className="back-info">Simpan .xls</a>
                                </div>
                                <div className="simpan simpanTravel">
                                  <a href={`${baseurl.API}/v1/vendor/download_policy_user/4/${this.props.match.params.ids}`} className="back-info">Simpan .pdf</a>
                                </div>
                              </div>
                            </div>
                            {/**<div className="subJudul">
                                  <h3>Peserta Dewasa 1</h3>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Nama Lengkap</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>s</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Hubungan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>s</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Tanggal Lahir</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>s</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>No Passpor/KTP</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>s</p>
                                    </div>
                                  </div>
                                </div>**/}
                            <hr />
                          </div>

                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Nama Produk</h4>
                          <p>{this.state.dataPolis.insurance_product}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Masa Berlaku</h4>
                          <p>{this.state.dataPolis.validity_period}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Nomor Polis</h4>
                          <p>{this.state.dataPolis.policy_number ? this.state.dataPolis.policy_number : '-'}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Status Polis</h4>
                          <p>{this.state.dataPolis.status}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Jenis Perlindungan</h4>
                          <p>{this.state.dataPolis.protection_type}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Tujuan Perjalanan</h4>
                          <p>{this.state.dataPolis.travel.destination ? this.state.dataPolis.travel.destination : '-'}</p>
                        </div>

                        <div className="asuransi col-md-2">
                          <h4>Total Premi</h4>
                          <p className="redFont">{this.state.dataPolis.grand_total ? this.convertToRupiah(parseInt(dataPolis.grand_total)) : "-"}</p>
                        </div>
                      </div>
                      <div className="asuransi">
                        <h4>Manfaat Produk</h4>
                        <ol>
                          {this.state.benefit.map((data, key) => {
                            return <li key={key}>{data.benefit_title}</li>
                          })}
                        </ol>
                      </div>
                      <div className="upload">
                        <Button onClick={this.toggleB}>Upload Polis</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleB} size="700px" className="modal-upload">
                          <ModalBody>
                            <div className="upload">
                              <div className="container">
                                <div className="row">
                                  <div className="col-sm-8">

                                    <UploadFile url={this.props.match.params.ids} number={this.state.dataPolis.policy_number}/>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                      <div className="status">
                        <h5>Endorsement Status</h5>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nomor</th>
                              <th>Keterangan</th>
                              <th>File</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.Endorsement.length >= 1 ? this.state.Endorsement.map((data, key) => {
                              let str = data.file_name
                              return <tr key={key}>
                                <td>{this.state.dataPolis.policy_number}</td>
                                <td>{data.description}</td>
                                <td><a href={`${data.file_path}`}>{str}</a></td>
                              </tr>
                            }) : <tr><td colSpan="3" className="redFont text-center">Data Endorsement Kosong, Silahkan Upload Polis.</td></tr>}
                            {/* <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}

export default AsuransiPolisTravel
