import React, {Component} from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import show from '../../../assets/img/view-password.svg'
import * as actions from '../../../store/actions/auth'

class FormLogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'password',
      garis: 'garis',
      email: '',
      password: '',
      emailForgot: '',
      open: false,
      loader: true
    }
    this.onOpenModal = this.onOpenModal.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.onForgotHandler = this.onForgotHandler.bind(this)
    this.showPass = this.showPass.bind(this)
    this.onChangeHandle = this.onChangeHandle.bind(this)
  }
  showPass() {
    if(this.state.type === 'password') {
      this.setState({
        type: 'text',
        garis: ''
      })
    } else {
      this.setState({
        type: 'password',
        garis: 'garis'
      })
    }
  }
  onOpenModal() {
    this.setState({open: true})
  }
  onCloseModal() {
    this.setState({open: false})
  }
  onChangeHandle(e) {
    e.preventDefault
    const {
      name,
      value
    } = e.target
    this.setState({
      [name]: value
    })
  }
  componentDidMount() {
    if (!this.props.buildingBurger && this.props.authRedirectPath !== '/') {
      this.props.onSetAuthRedirectPath()
    }
    this.setState({
      loader: false
    })
  }
  submitHandler(e) {
    e.preventDefault()
    this.props.onAuth(this.state.email, this.state.password)
  }
  onForgotHandler(e) {
    e.preventDefault()
    this.props.onForgotPassword(this.state.emailForgot)
  }
  render() {
    const { open, email, password, emailForgot } = this.state
    let errorMessage = ""
    console.log('loader', this.state.loader)
    if(this.props.error) {
      errorMessage = (
        <p className="alert-login">Username atau Password Salah!</p>
      )
    }
    if (this.state.loader) {
      return (<h3>Loading</h3>)
    } else {
      return (
        <div className="login">
          <h3>Selamat Datang</h3>
          <form onSubmit={this.submitHandler}>
            <div className="input">
              <label htmlFor="email">
                <div>Email</div>
                <input type="email" className="email-text" name="email" value={email} onChange={this.onChangeHandle}/>
              </label>
            </div>
            <div className="input">
              <label htmlFor="password">
                <div>Password</div>
                <input type={this.state.type} className="password" name="password" value={password} onChange={this.onChangeHandle} />
                <div className="checkform" onClick={this.showPass}>
                  <span className={this.state.garis}></span>
                  <img src={show} alt="show" className="img-show" />
                </div>
              </label>
            </div>
            {errorMessage}
            <div className="checkbox">
              <input
                type="checkbox"
                name="checkbox"
                value=""
                className="inputC"
              />Ingat Kata Sandi
            </div>
            <div className="submit">
              <input
                type="submit"
                name="submit"
                value="Masuk"
                className="inputS"
              />
            </div>
          </form>
                    
          <div className="link">
            <button onClick={this.onOpenModal}>Lupa Kata Sandi</button>{}
            <Modal open={open} onClose={this.onCloseModal} center>
              <div className="login kataSandi">
                <h3>Lupa Kata Sandi?</h3>
                <h5>Masukan E-mail Anda</h5>
                <form onSubmit={this.onForgotHandler}>
                  <div className="input">
                    <label htmlFor="email">
                      <div>Email</div>
                      <input type="email" className="email-text" name="emailForgot" value={emailForgot} onChange={this.onChangeHandle}/>
                    </label>
                  </div>
                  <div className="submit">
                    <input
                      type="submit"
                      name="submit"
                      value="KIRIM"
                      className="inputS"
                    />
                  </div>
                </form>

                <div className="ulang">
                  <h4>Tidak terima email? <a href="">Kirim Ulang</a></h4>
                </div>    
              </div>
            </Modal>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetAuthRedirectPath:  () => dispatch(actions.setAuthRedirectPath),
    onForgotPassword: (email) => dispatch(actions.getForgotPassword(email))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormLogin)
