import axios from 'axios'
import baseUrl from '../../helpers/constants'
import * as actionTypes from './actionTypes'

export const sortByTerbaruPolis = (data) => {
  return {
    type: actionTypes.SORT_BY_TERBARU,
    sortPolis: data
  }
}
export const sortByTerlamaPolis = (data) => {
  return {
    type: actionTypes.SORT_BY_TERLAMA,
    sortPolis: data
  }
}
export const sortStartPolis = () => {
  return {
    type: actionTypes.SORT_START
  }
}
export const sortSearchPolis = (data) => {
  return {
    type: actionTypes.SORT_SEARCH_POLIS,
    sortPolis: data
  }
}


export const dataTerbaruPolis = (sort, solution) => {
  return dispatch => {
    dispatch(sortStartPolis())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    const sortBy = {
      sort: sort,
      page: 1,
      filter: ""
    }
    console.log('sor', solution)
    axios.post(`${baseUrl.API}/v1/vendor/policy/${solution}/1`, sortBy)
      .then(res => {
        const data = res.data
        dispatch(sortByTerbaruPolis(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export const dataTerlamaPolis = (sort, solution) => {
  return dispatch => {
    dispatch(sortStartPolis())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    const sortBy = {
      sort: sort
    }

    axios.post(`${baseUrl.API}/v1/vendor/policy/${solution}/1`, sortBy)
      .then(res => {
        const data = res.data
        dispatch(sortByTerlamaPolis(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export const dataSearchPolis = (search, solution) => {
  return dispatch => {
    dispatch(sortStartPolis())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    const sortBySearch = {
      filter: search
    }
    axios.post(`${baseUrl.API}/v1/vendor/policy/${solution}/1`, sortBySearch)
      .then(res => {
        const data = res.data
        dispatch(sortSearchPolis(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}
