import React, { Component } from 'react'
import Navigasi from '../Navigate/Navigasi'
import FormProduk from './component/FormProduk'
import ListProduk from './component/ListProduk'
import jp_health from '../../assets/img/jp-health.png'

class PersonalPromotion extends Component {
  render(){
    return (
      <div>
        <Navigasi />
        <div className="banner-produk">
          <div className="judul-produk">
            <h3>Promosi</h3>
            <img src={jp_health} className="img-judul" alt="" />
          </div>
          <div className="produk-info">
            <div className="container">
              <FormProduk />
              <ListProduk />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalPromotion
