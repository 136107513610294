/* eslint-disable no-undef */
import * as actionTypes from '../actions/actionTypes'
import {
  updateObject
} from '../utility'

const initialState = {
  token: null,
  name: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  message: ''
}

const authStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    name: action.name,
    token: action.token,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const authLogout = (state) => {
  return updateObject(state, {
    token: null,
    name: null
  })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path
  })
}
const alertSucces = (state, action) => {
  return updateObject(state, {
    message: action.message
  })
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_START:
    return authStart(state, action)
  case actionTypes.AUTH_SUCCESS:
    return authSuccess(state, action)
  case actionTypes.AUTH_FAIL:
    return authFail(state, action)
  case actionTypes.AUTH_LOGOUT:
    return authLogout(state, action)
  case actionTypes.SET_AUTH_REDIRECT_PATH:
    return setAuthRedirectPath(state, action)
  case actionTypes.CHANGE_SANDI:
    return alertSucces(state, action)
  default:
    return state
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch(logout())
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'))
      if (expirationDate <= new Date()) {
        dispatch(logout())
      } else {
        const userId = localStorage.getItem('userId')
        dispatch(authSuccess(token, userId))
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
      }
    }
  }
}

export default authReducer
