import axios from 'axios'
import baseUrl from '../../helpers/constants'
import * as actionTypes from './actionTypes'


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, name) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    name: name
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userId')
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart())

    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    const authData = {
      email: email,
      password: password
    }
    let url = `${baseUrl.API}/v1/auth/login`
    axios.post(url, authData)
      .then(response => {
        let data = response.data.data
        if (!data.message) {
          localStorage.setItem('token', response.data.data.token)
          localStorage.setItem('userId', response.data.data.name)
          dispatch(authSuccess(data.token, data.name))
          window.location='/'
        } else {
          dispatch(authFail(data.message))
        }
      })

  }
}

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const alertSucces = (message) => {
  return {
    type: actionTypes.CHANGE_SANDI,
    message: message
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch(logout())
    } else {
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //     dispatch(logout());
      // } else {
      //     const userId = localStorage.getItem('name');
      //     dispatch(authSuccess(token, userId));
      //     dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
      // }
    }
  }
}
export function getForgotPassword (email) {
  console.log(email)
  const data = {
    email: email
  }
  return () => {
    axios.post(`${baseUrl.API}/v1/auth/forgot_password`, data)
      .then(response => {
        console.log(response)
      })
  }
}
export function GantiKataSandi(sandiLama, baru, baruConfirm) {

  return dispatch => {
    const data2 = {
      current_password: sandiLama,
      new_password: baru,
      confirm_password: baruConfirm
    }
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    axios.post(`${baseUrl.API}/v1/auth/change_password`, data2)
      .then(response => {
        console.log(response.data.data.message)

        dispatch(alertSucces(response.data.data.message))
        if(response.data.data.message == "password has been changed") {
          dispatch(logout())
          location.reload()
        }
      })
  }
}
