import React, {Component} from "react"
import Navigasi from '../Navigate/Navigasi'
import iconPolis from "../../assets/img/icon-polis.png"
import Banner from "../../assets/img/banner-health.png"

import { NavLink } from "react-router-dom"

class MenuPersonal extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="banner-kategori">
          <img
            src={Banner}
            className="banner-img"
            alt="banner-personal"
          />
        </div>
        <div className="container">
          <div className="pilih">
            <ul className="row">
              {/* <li className="col-4">
                <NavLink to="/personal/produk">
                  <img src={iconProduk} />
                  <div className="icon-info">
                    <h3>Produk</h3>
                  </div>
                </NavLink>
              </li>
              <li className="col-4">
                <NavLink to="/personal/promosi">
                  <img src={iconPromosi} />
                  <div className="icon-info">
                    <h3>Promosi</h3>
                  </div>
                </NavLink>
              </li> */}
              <li className="col-4">
                <NavLink to="/personal/polis">
                  <img src={iconPolis} />
                  <div className="icon-info">
                    <h3>Polis</h3>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuPersonal
