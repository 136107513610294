import React, { Component } from 'react'
import FormProduk from './component/FormProduk'
import ListProduk from './component/ListProduk'
import jp_vehicle from '../../assets/img/jp-travel.png'
import Navigasi from '../Navigate/Navigasi'

class ProdukTravel extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="banner-property">
          <div className="judul-travel">
            <h3>Produk Anda</h3><img src={jp_vehicle} className="img-judul" alt="" />
          </div>
          <div className="produk-info">
            <div className="container">
              <FormProduk />
              <ListProduk />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProdukTravel
