import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import App from './App'
import "bootstrap/dist/css/bootstrap.min.css"
import "./style/style.scss"

import homeReducer from './store/reducers/home'
import authReducer from './store/reducers/auth'
import sortReducer from './store/reducers/sort'
import sortPolisReducer from './store/reducers/sortPolis'
import contactReducer from './store/reducers/contact'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  sort: sortReducer,
  sortPolis: sortPolisReducer,
  contact: contactReducer
})

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))


ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>), document.getElementById('root'))
