import React, {Component} from 'react'
import {
  Col,
  Container,
  Row
} from 'reactstrap'
import logo from '../../../assets/img/logo.svg'

class Header extends Component {
  render(){
    return(
      <div className="header">
        <Container>
          <Row>
            <Col xs="6" sm="3">
              <div className="brand">
                <img src={logo} className="logo" alt="logo" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Header
