import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/sortPolis'
import iconSubmit from "../../../assets/img/magnifier-icon.png"

class FormPolis extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popoverOpen: false,
      default: 1,
      list: this.props.sortBy,
      loader: true,
      search: ''
    }
    this.onChange = this.onChange.bind(this)
    this.onClickChange = this.onClickChange.bind(this)
  }
  componentDidMount() {
    this.setState({
      loader: false
    })
  }
  onChange(e) {
    e.preventDefault()
    const {
      name,
      value
    } = e.target
    this.setState({
      [name]: value
    })
  }
  onClickChange(e) {
    e.preventDefault()
    this.props.onSearch(this.state.search, 1)
  }
  render() {
    const { search } = this.state

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-search">
            <form onSubmit={this.onClickChange}>
              <input type="text" className="input" name="search" value={search} onChange={this.onChange} placeholder="Ketik Nama....." />
              <button>
                <img src={iconSubmit} />
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    sortBy: state.sortPolis.sortBy,
    sortName: state.sortPolis.sortName
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSearch: (search, solution) => dispatch(actions.dataSearchPolis(search, solution))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPolis)
