import React, { Component } from 'react'
import notFound from "../../../assets/img/profile-icon.png"
import Navigasi from "../../Navigate/Navigasi"
import { TabContent, TabPane, Nav, NavItem, NavLink, Col, Row} from 'reactstrap'
import classnames from 'classnames'
import {Button, Modal, ModalBody} from 'reactstrap'
import axios from 'axios'
import Loading from '../../loading'
import UploadFile from './UploadFile'
import baseurl from '../../../helpers/constants'

class AsuransiPolisVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '2',
      modal: false,
      dataPolis: [],
      benefit: [],
      Endorsement: [],
      loader1: false,
      loader2: false,
      dataProfile: {}
    }
    this.toggle = this.toggle.bind(this)
    this.toggleB = this.toggleB.bind(this)
    this.download = this.download.bind(this)
    this.convertToRupiah = this.convertToRupiah.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
	
  download () {
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    axios.get(`${baseurl.API}/v1/vendor/download_policy_as_excel/2/${this.props.match.params.ids}/xlsx`).then(() => {
    }).catch(() => {
    })
  }

  toggleB() {
    this.setState({
      modal: !this.state.modal
    })
  }

  convertToRupiah(val) {
    const numberString = val.toString()
    const sisa = numberString.length % 3
    let rupiah = numberString.substr(0, sisa)
    const ribuan = numberString.substr(sisa).match(/\d{3}/g)
    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }
    return `Rp ${rupiah}`
  }

  componentDidMount() {
    const {
      match
    } = this.props

    const getSolution = match.path.split('/')
    let solution = ''
    switch (getSolution[1]) {
    case 'personal':
      solution = 1
      break
    case 'vehicle':
      solution = 2
      break
    case 'property':
      solution = 3
      break
    case 'travel':
      solution = 4
      break
    }
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    axios.get(`${baseurl.API}/v1/vendor/detail_policy_user/${solution}/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader1: true,
          dataPolis: dataPolis,
          benefit: dataPolis.benefit_premia
        })
      })
      .catch(() => {
      })

    axios.get(`${baseurl.API}/v1/endorsement_status/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader2: true,
          Endorsement: dataPolis
        })
      })
      .catch(() => {
      })

  }

  render() {
    const {
      dataPolis,
      loader1
    } = this.state
    if(!(loader1 && loader1)){
      return (
        <div>
          <Navigasi />
          <div className="profil-user">
            <div className="container">
              <Nav tabs>
                {/* <NavItem>
									<Link
															to = {"/vehicle/polis/"+ this.props.match.params.id} //+ this.match.params.id}
										className="nav-link"
										onClick={() => { this.toggle('1') }}
									>
										<button>Profil</button>
									</Link>
								</NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2') }}
                  >
                    <button>Asuransi</button>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <h3>Anna Lorignhton</h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <h4>Tempat Lahir</h4>
                          <p>Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Tanggal Lahir</h4>
                          <p>3 Januari 1986</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Jenis Kelamin</h4>
                          <p>Wanita</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Pekerjaan</h4>
                          <p>Karyawan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomor NPWP</h4>
                          <p>123456789098765</p>
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <h4>Alamat Lengkap</h4>
                          <p>Jl.H.R. Rasuna Said XV</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Provinsi</h4>
                          <p>DKI Jakarta</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kota</h4>
                          <p>Jakarta Selatan</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Kode Pos</h4>
                          <p>12240</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer Telepon Rumah</h4>
                          <p>0217228899</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Nomer HP</h4>
                          <p>08189992222</p>
                          <hr />
                        </div>
                        <div className="col-sm-4">
                          <h4>Email</h4>
                          <p>anna@gmail.com</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Loading />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Navigasi />
        <div className="profil-user">
          <div className="container">
            <Nav tabs>
              {/* <NavItem>
								<Link
									to = {"/vehicle/polis/"+ this.props.match.params.id} //+ this.match.params.id}
									className="nav-link"
									onClick={() => { this.toggle('1') }}
								>
									<button>Profil</button>
								</Link>
							</NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2') }}
                >
                  <button>Asuransi</button>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="photo-polis">
                      {
                        dataPolis.publisher_logo ?
                          (
                            <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                          ) : (
                            <img className="image-profile" src={notFound} alt="" />
                          )
                      }
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <h3>Anna Lorignhton</h3>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <h4>Tempat Lahir</h4>
                        <p>Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Tanggal Lahir</h4>
                        <p>3 Januari 1986</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Jenis Kelamin</h4>
                        <p>Wanita</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Pekerjaan</h4>
                        <p>Karyawan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor NPWP</h4>
                        <p>123456789098765</p>
                        <hr />
                      </div>
                      <div className="col-sm-12">
                        <h4>Alamat Lengkap</h4>
                        <p>Jl.H.R. Rasuna Said XV</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Provinsi</h4>
                        <p>DKI Jakarta</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kota</h4>
                        <p>Jakarta Selatan</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kode Pos</h4>
                        <p>12240</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer Telepon Rumah</h4>
                        <p>0217228899</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomer HP</h4>
                        <p>08189992222</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Email</h4>
                        <p>anna@gmail.com</p>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="asuransi-info">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="row order-info">
                        <div className="informasi">
                          <div className="informasiDiri">
                            <div className="judulPolis">
                              <h3>Informasi Data Diri</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Alamat Lengkap Sesuai KTP</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.address_detail : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Provinsi</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.province : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kota</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.city : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kecamatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.district : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kelurahan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.village : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kode Pos</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.postal_code : '-'}</p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="judulPolis">
																	<h3>Informasi Data Diri</h3>
																</div>
																<div className="row">
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Nama Lengkap</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.full_name : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Tempat Lahir</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.birth_place : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Tanggal Lahir</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.birth_date : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Jenis Kelamin</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.gender : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Status Pernikahan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.marriage_status : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Nomor KTP/NIK/KITAS/SIM</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.identity_number : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Nomor NPWP</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.npwp_number : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Pekerjaan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.jobs : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Sumber Pendapatan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.source_income : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Jumlah Pendapatan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.total_salary : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Alamat Lengkap Sesuai KTP</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.address_detail : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Provinsi</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.province : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Kota</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.city : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Kecamatan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.district : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Kelurahan</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.village : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Kode Pos</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.postal_code : '-'}</p>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-3">
																		<div className="judul-box">
																			<h4>Email</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.email : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Nomor Telepon</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.phone_number : '-'}</p>
																		</div>
																	</div>
																	<div className="col-md-2">
																		<div className="judul-box">
																			<h4>Nomor Handphone</h4>
																		</div>
																		<div className="data-box">
																			<p>{dataPolis.profile ? dataPolis.profile.mobile_number : '-'}</p>
																		</div>
																	</div>
																</div>
																<hr /> */}
                          </div>
                          <div className="dataKendaraan">
                            <div className="judulPolis">
                              <h3>Informasi Kendaraan</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Nama Lengkap</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.vehicle.name ? dataPolis.vehicle.name : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="judul-box">
                                      <h4>Nomor Plat Kendaraan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.vehicle.plat_number ? dataPolis.vehicle.plat_number : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="judul-box">
                                      <h4>Tahun Kenderaan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.vehicle.year ? dataPolis.vehicle.year : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="judul-box">
                                      <h4>Nomor Mesin</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.vehicle.machine_number ? dataPolis.vehicle.machine_number : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="judul-box">
                                      <h4>Nomor Rangka</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.vehicle.fabric_number ? dataPolis.vehicle.fabric_number : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Merek-Tipe-Seri Mobil</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.vehicle.merk_type_model ? dataPolis.vehicle.merk_type_model : '-'}</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="foto">
                              <div className="fotoJudul">
                                <h5>Foto Identitas</h5>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>KTP</h5>
                                    <p>ktp.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>STNK</h5>
                                    <p>stnk.jp</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fotoKendaraan">
                              <div className="fotoJudul">
                                <h5>Foto Kendaraan</h5>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Depan</h5>
                                    <p>sisi depan.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Belakang</h5>
                                    <p>sisi belakang.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Kiri</h5>
                                    <p>sisi kiri.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="simpan">
                                    <a href={`${baseurl.API}/v1/vendor/download_policy_as_excel/2/${this.props.match.params.ids}/xlsx`} className="back-info">Simpan .xls</a>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Kanan</h5>
                                    <p>sisi kanan.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Depan</h5>
                                    <p>sisi depan.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="box">
                                    <h5>Sisi Serong Belakang</h5>
                                    <p>sisi serong belakang.jpg</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
																			
                                  <div className="simpan">
                                    <a href={`${baseurl.API}/v1/vendor/download_policy_user/2/${this.props.match.params.ids}`} className="back-info">Simpan .pdf</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Nama Produk</h4>
                          <p>{this.state.dataPolis.insurance_product}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Masa Berlaku</h4>
                          <p>{this.state.dataPolis.validity_period}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Nomor Polis</h4>
                          <p>{this.state.dataPolis.policy_number ? this.state.dataPolis.policy_number : '-'}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Status Polis</h4>
                          <p>{this.state.dataPolis.status}</p>
                        </div>
														
                        <div className="asuransi col-md-4">
                          <h4>Jenis Perlindungan</h4>
                          <p>{this.state.dataPolis.protection_type ? this.state.dataPolis.protection_type : '-'}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Total Premi</h4>
                          <p className="redFont">{this.state.dataPolis.grand_total ? this.convertToRupiah(parseInt(dataPolis.grand_total)) : "-"}</p>
                        </div>
                        <div className="col-md-12">
                          {dataPolis.publisher_title !== "HARTA"? (<div className="row">
                            <div className="asuransi col-md-6">
                              <h4>Perluasan Cover</h4>
                              { dataPolis.extentions ?
                                dataPolis.extentions.map((data, key) => {
                                  return(
                                    <li key={key}>
                                      <span>{data.title_extention}</span>
                                      <span className="price-info">{this.convertToRupiah(parseInt(data.price))}</span>
                                    </li>
                                  )
                                }) : '-'
                              }
                            </div>
                            <div className="asuransi col-md-6">
                              <h4>Tambahan</h4>
                              { dataPolis.additionals ?
                                dataPolis.additionals.map((data, key) => {
                                  return(
                                    <li key={key}>
                                      <span>{data.title_extention}</span>
                                      <span className="price-info">{this.convertToRupiah(parseInt(data.price))}</span>
                                    </li>
                                  )
                                }) : '-'
                              }
                            </div>
                          </div>) : (<div className="row">
                            <div className="asuransi col-md-12">
                              <h4>Perluasan Cover</h4>
                              {dataPolis.extentions ?
                                dataPolis.extentions.map((data, key) => (
                                  <Row key={key}>
                                    <Col md="6">
                                      <span>{data.title_extention}</span>
                                    </Col>
                                    {data.limit === 0? (
                                      <React.Fragment>
                                        <Col md="4" xs="6">
                                          <span>{`${this.convertToRupiah(parseInt(dataPolis.vehicle.vehicle_price))} x ${data.rate_after_substract_with_discount}%`}</span>
                                        </Col>
                                        <Col md="2" xs="6">
                                          <span className="price-info">{this.convertToRupiah(parseFloat(dataPolis.vehicle.vehicle_price) * data.rate_after_substract_with_discount/100)}</span>
                                        </Col>
                                      </React.Fragment>) : (
                                      <React.Fragment>
                                        <Col md="4" xs="6">
                                          <span>{
                                            data.title_extention !== 'Tanggung Jawab Terhadap Pihak Ketiga (TPL)' ? 
                                              `${this.convertToRupiah(parseInt(data.limit))} x ${data.rate_after_substract_with_discount}%` : ''}</span>
                                        </Col>
                                        <Col md="2" xs="6">
                                          <span className="price-info">{
                                            data.title_extention !== 'Tanggung Jawab Terhadap Pihak Ketiga (TPL)' ?
                                              // eslint-disable-next-line max-len
                                              this.convertToRupiah(parseFloat(data.limit) * data.rate_after_substract_with_discount/100) : this.convertToRupiah(parseInt(data.price - (data.price * parseFloat(dataPolis.percentage_of_publisher_discount)/100)))}
                                          </span>
                                        </Col>
                                      </React.Fragment>)}
                                  </Row>
                                )) : ''}	
                              {/* <h4>Perluasan Cover</h4>
																	{ dataPolis.extentions ?
																		dataPolis.extentions.map((data, key) => {
																			return(
																				<li key={key}>
																					<span>{data.title_extention}</span>
																					<span className="price-info">{this.convertToRupiah(parseInt(data.price))}</span>
																				</li>
																			)
																		}) : '-' */}
                            </div>
                            <div className="asuransi col-md-12">
                              <h4>Tambahan</h4>
                              {dataPolis.additionals ?
                                dataPolis.additionals.map((data, key) => (
                                  <Row key={key}>
                                    <Col md="6">
                                      <span>{data.title_extention}</span>
                                    </Col>
                                    <Col md="4" xs="6">
                                      <span>{`${this.convertToRupiah(parseInt(dataPolis.vehicle.vehicle_price))} x ${data.rate_after_substract_with_discount}%`}</span>
                                    </Col>
                                    <Col md="2" xs="6">
                                      <span className="price-info">{this.convertToRupiah(parseFloat(dataPolis.vehicle.vehicle_price) * data.rate_after_substract_with_discount/100)}</span>
                                    </Col>
                                  </Row>
                                )) : ''}
                            </div>
                          </div>)
                          }
                        </div>
                      </div>
                      <div className="asuransi">
                        <h4>Manfaat Produk</h4>
                        <ol>
                          {this.state.benefit.map((data, key) => {
                            return <li key={key}>{data.benefit_title}</li>
                          })}
                        </ol>
                      </div>
                      <div className="upload">
                        <Button onClick={this.toggleB}>Upload Polis</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleB} size="700px" className="modal-upload">
                          <ModalBody>
                            <div className="upload">
                              <div className="container">
                                <div className="row">
                                  <div className="col-sm-8">

                                    <UploadFile url={this.props.match.params.ids} number={this.state.dataPolis.policy_number}/>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                      <div className="status">
                        <h5>Endorsement Status</h5>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nomor</th>
                              <th>Keterangan</th>
                              <th>File</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.Endorsement.length >= 1 ? this.state.Endorsement.map((data, key) => {
                              let str = data.file_name
                              return <tr key={key}>
                                <td>{this.state.dataPolis.policy_number}</td>
                                <td>{data.description}</td>
                                <td><a href={`${data.file_path}`}>{str}</a></td>
                              </tr>
                            }) : <tr><td colSpan="3" className="redFont text-center">Data Endorsement Kosong, Silahkan Upload Polis.</td></tr>}
                            {/* <tr>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}

export default AsuransiPolisVehicle
