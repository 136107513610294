import React, { Component } from 'react'
import { PopoverBody, Popover, Modal, ModalBody } from 'reactstrap'
import show from '../../../assets/img/view-password.svg'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/auth'
import profil from '../../../assets/img/profil-placeholder.svg'
import bawah from '../../../assets/img/arrow-down-blue.svg'

class PopoverProf extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popoverOpen: false,
      modalOpen: false,
      type1: "password",
      type2: "password",
      type3: "password",
      garis: "garis",
      garis1: "garis",
      garis2: "garis",
      sandiBaru: '',
      sandiBaruConfirm: '',
      sandiLama: ''
    }
    this.toggle = this.toggle.bind(this)
    this.openModal = this.openModal.bind(this)
    this.showPass = this.showPass.bind(this)
    this.showPass1 = this.showPass1.bind(this)
    this.showPass2 = this.showPass2.bind(this)
    this.onChangeHandle = this.onChangeHandle.bind(this)
    this.onSubmitHandle = this.onSubmitHandle.bind(this)
    this.logout = this.logout.bind(this)
  }
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }
  showPass() {
    if (this.state.type1 === 'password') {
      this.setState({
        type1: 'text',
        garis: ''
      })
    } else {
      this.setState({
        type1: 'password',
        garis: 'garis'
      })
    }
  }
  showPass1() {
    if (this.state.type2 === 'password') {
      this.setState({
        type2: 'text',
        garis1: ''
      })
    } else {
      this.setState({
        type2: 'password',
        garis1: 'garis'
      })
    }
  }
  showPass2() {
    if (this.state.type3 === 'password') {
      this.setState({
        type3: 'text',
        garis2: ''
      })
    } else {
      this.setState({
        type3: 'password',
        garis2: 'garis'
      })
    }
  }
  openModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }
  handleLogout(e){
    e.preventDefault()
    this.props.onLogout()
  }
  onChangeHandle(e) {
    e.preventDefault
    const {
      name,
      value
    } = e.target
    this.setState({
      [name]: value
    })
    console.log(this.state.sandiLama)
  }
  onSubmitHandle(e) {
    e.preventDefault()
    this.props.onChangeSandi(this.state.sandiLama, this.state.sandiBaru, this.state.sandiBaruConfirm)
    // if(this.props.message == "password has been changed") {
    //     console.log("logg jalan")
    // }  else {
    //     console.log("sdsd",this.props.message)
    // }
  }
  logout() {
    this.props.onLogout()
    location.reload()
  }
  render() {
    const { sandiLama, sandiBaru, sandiBaruConfirm } = this.state
    let message = ""
    const user = localStorage.getItem('userId')

    if (this.props.message) {
      switch (this.props.message) {
      case "current password is wrong":
        message = "Password lama anda salah"
        break
      case "new password and confirm password do not match":
        message = "Password tidak sesuai"
        break
      default:
        break
      }
    }
    if (this.state.sandiBaru == this.state.sandiBaruConfirm && !(this.state.sandiBaru == '') && !(this.state.sandiBaruConfirm == '')) {
      message = "Kata sandi sesuai"
    }
    return (
      <div>
        <button id="Popover1" onClick={this.toggle}>
          <img src={profil}
            className="profil"
            alt="profil"
          />
          <img src={bawah} alt="bawah" />
        </button>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1">
          <PopoverBody>
            <div className="profile">
              <div className="container">
                <div className="row">
                  <div className="col-4">
                    <div className="img">
                      <img src={profil
                      } className="logo-profile" />
                    </div>
                  </div>
                  <div className="info-profil col-8">
                    <h3>{ user }</h3>
                    {/*<span>{ user }</span>*/}
                  </div>
                  <div className="col-7">
                    <button className="gantiSandi" onClick={this.openModal}>Ubah Sandi</button>
                  </div>
                  <div className="col-3">
                    <button className="keluar" onClick={this.logout}>Keluar</button>
                  </div>
                </div>
              </div>
            </div>
          </PopoverBody>
        </Popover>
        <Modal isOpen={this.state.modalOpen} toggle={this.openModal} modalClassName="ModalUbahSandi">
          <ModalBody>
            <div className="login kataSandi">
              <h3>Ubah Kata Sandi</h3>
              <form onSubmit={this.onSubmitHandle}>
                <div className="input">
                  <label htmlFor="email">
                    <div>Kata Sandi anda</div>
                    <input type={this.state.type1}
                      className="email-text"
                      name="sandiLama"
                      value={sandiLama}
                      onChange={this.onChangeHandle}/>
                  </label>
                  <div className="checkform" onClick={this.showPass}>
                    <span className={this.state.garis}></span>
                    <img src={show} alt="show" className="img-show" />
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="password">
                    <div>Kata sandi baru</div>
                    <input type={this.state.type2}
                      className="password"
                      name="sandiBaru"
                      value={sandiBaru}
                      onChange={this.onChangeHandle}/>
                  </label>
                  <div className="checkform" onClick={this.showPass1}>
                    <span className={this.state.garis1}></span>
                    <img src={show} alt="show" className="img-show" />
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="password">
                    <div>Kata sandi baru</div>
                    <input type={this.state.type3}
                      className="password"
                      name="sandiBaruConfirm"
                      value={sandiBaruConfirm}
                      onChange={this.onChangeHandle}/>
                  </label>
                  <div className="checkform" onClick={this.showPass2}>
                    <span className={this.state.garis2}></span>
                    <img src={show} alt="show" className="img-show" />
                  </div>
                </div>
                <p className="alert-login">{message}</p>
                <div className="submit">
                  <input
                    type="submit"
                    name="submit"
                    value="SIMPAN"
                    className="inputS"
                  />
                </div>
              </form>

            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    message: state.auth.message
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onLogout: () => dispatch(actions.logout()),
    onChangeSandi: (sandiLama, baru, baruConfirm) => dispatch(actions.GantiKataSandi(sandiLama, baru, baruConfirm))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopoverProf)
