import React, {
  Component
} from 'react'
import {
  withRouter
} from 'react-router-dom'
import {
  connect
} from 'react-redux'
import Router from './router'
import Navigasi from './Components/Navigate/Navigasi'
import Footer from './Components/Footer/Footer'
import * as actions from './store/actions/index'

let token = ''

class App extends Component {
  // componenDidMount(){
  //   token = localStorage.getItem("token");
  // }
  render() {
    token = localStorage.getItem("token")
    let nav = <Navigasi />

    if (!token) {
      // eslint-disable-next-line no-unused-vars
      nav = ""
    }
    return (
      <div>
        <Router />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
