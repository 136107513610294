import axios from 'axios'
import baseUrl from '../../helpers/constants'
import * as actionTypes from './actionTypes'

export const contactStart = () => {
  return {
    type: actionTypes.CONTACT_START
  }
}

export const contactSucces = (data) => {
  return {
    type: actionTypes.CONTACT_SUCCESS,
    data
  }
}

export const contactFail = (error) => {
  return {
    type: actionTypes.CONTACT_FAIL,
    error: error
  }
}

export const contactKirim = (params) => {
  return dispatch => {
    dispatch(contactStart())

    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    const param = params

    axios.post(`${baseUrl.API}/v1/vendor/insurance-contact-us`, param)
      .then(response => {
        dispatch(contactSucces(response))
      })
      .catch((err) => {
        dispatch(contactFail(err))
      })
  }
}
