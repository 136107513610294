import React, {Component} from 'react'
import {PopoverBody, Popover} from 'reactstrap'
import notifikasi from '../../../assets/img/notification-off.svg'
import baseurl from '../../../helpers/constants'
import notifikasi_img from "../../../assets/img/notification-produk.png"
import axios from 'axios'

class PopoverNot extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popoverOpen: false,
      data: [],
      read: false,
      readed: true
    }
    this.toggle = this.toggle.bind(this)
  }
  componentDidMount() {
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    axios.get(`${baseurl.API}/v1/vendor/notification`)
      .then(res => {
        this.setState({
          data: res.data.data
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
      readed: false
    })
    if (!this.state.read) {
      axios.defaults.headers.common = {
        'Accepts-Version': 1,
        Accept: 'application/json',
        Platform: 'website',
        'Acces-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
        token: localStorage.getItem('token')
      }
      axios.post(`${baseurl.API}/v1/vendor/mark-as-read`)
        .then(() => {
          this.setState({
            read: true
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }


  render() {
    const {
      data,
      readed
    } = this.state
    const list_notifikasi = data.notifications ? data.notifications : ''
    const number_of_not = data.number_of_notifications
    return (
      <div>
        <button id="Popover" onClick={this.toggle}>
          <img
            src={notifikasi}
            className="notifikasi-img"
            alt="notifikasi"
          />
          {number_of_not > 0 && readed ? <div className="numberNotif">{number_of_not}</div> : ''}
        </button>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover">
          <PopoverBody>
            { number_of_not > 0 ?
              (
                <div className="notifikasiNavigasi">
                  <ul>
                    {list_notifikasi.map((notif, id) => {
                      return (
                        <li className="row" key={id}>
                          <div className="col-3">
                            <img src={notifikasi_img} className="img-notifikasi" />
                          </div>
                          <div className="info col-9">
                            <h3>{notif.notifiable_type}</h3>
                            <h4>{notif.body}</h4>
                            {/**<span>1 jam yang lalu</span>**/}
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : (
                <div className="error_notif">
                  <span>Tidak ada notifikasi baru</span>
                </div>
              )
            }
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default PopoverNot
