import axios from 'axios'
import baseUrl from '../../helpers/constants'
import * as actionTypes from './actionTypes'

export const sortByTerbaru = (data) => {
  return {
    type: actionTypes.SORT_BY_TERBARU,
    sort: data
  }
}
export const sortByTerlama = (data) => {
  console.log('zilmas')
  return {
    type: actionTypes.SORT_BY_TERLAMA,
    sort: data
  }
}
export const sortStart = () => {
  return {
    type: actionTypes.SORT_START
  }
}
export const sortSearch = (data) => {
  return {
    type: actionTypes.SORT_SEARCH,
    sort: data
  }
}


export const dataTerbaru = (sort, ids) => {
  return dispatch => {
    dispatch(sortStart())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    const sortBy = {
      sort: sort,
      title: ""
    }

    console.log('sor', ids)
    axios.post(`${baseUrl.API}/v1/vendor/product/2/` + ids, sortBy)
      .then(res => {
        const data = res.data
        dispatch(sortByTerbaru(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export const dataTerlama = (sort, ids) => {
  return dispatch => {
    dispatch(sortStart())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    const sortBy = {
      sort: sort,
      title: ""
    }

    axios.post(`${baseUrl.API}/v1/vendor/product/2/` + ids, sortBy)
      .then(res => {
        const data = res.data
        dispatch(sortByTerlama(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const dataSearch = (search) => {
  return dispatch => {
    dispatch(sortStart())
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    const sortBySearch= {
      sort: "",
      title: search
    }
    axios.post(`${baseUrl.API}/v1/vendor/product/2/1`, sortBySearch)
      .then(res => {
        const data = res.data
        dispatch(sortSearch(data.data))
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }
}
