import React, { Component } from 'react'
import FormProduk from './component/FormProduk'
import jp_vehicle from '../../assets/img/jp-automotive-se.png'
import Navigasi from '../Navigate/Navigasi'
import Loading from '../loading'
class ProdukVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.setState({
      loading: false
    })
  }
  render() {
    if(this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      return (
        <div>
          <Navigasi />
          <div className="banner-vehicle">
            <div className="judul-vehicle">
              <h3>Produk Anda</h3><img src={jp_vehicle} className="img-judul" alt="" />
            </div>
            <div className="produk-info">
              <div className="container">
                <FormProduk/>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ProdukVehicle
