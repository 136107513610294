const initialState = {
  nama: ''
}

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
  default:
    return state
  }
}

export default homeReducer
