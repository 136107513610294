import React, { Component } from 'react'
import Navigasi from '../Navigate/Navigasi'
import jp_health from '../../assets/img/jp-health.png'
import FormPolis from './component/FormPolis'
import ListPolis from './component/ListPolis'

class Polis extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="judul-produk">
          <h3>Polis</h3><img src={jp_health} className="img-judul" alt="" />
        </div>
        <div className="polis-info">
          <div className="container">
            <FormPolis />
            <ListPolis />
          </div>
        </div>
      </div>
    )
  }
}

export default Polis
