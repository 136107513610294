import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/sortPolis'
import bawah from '../../../assets/img/arrow-down-blue.svg'
import notFound from '../../../assets/img/profile-icon.png'
import { Popover, PopoverBody } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import Loading from '../../loading'
class ListPolis extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popoverOpen: false
    }
    this.toggle = this.toggle.bind(this)
    this.onClickTerbaru = this.onClickTerbaru.bind(this)
    this.onClickTerlama = this.onClickTerlama.bind(this)
    this.handleNotifikasi = this.handleNotifikasi.bind(this)
  }
  componentDidMount() {
    this.props.onTerbaru(1, 2)
  }
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }
  onClickTerbaru(e) {
    e.preventDefault()
    this.props.onTerbaru(1, 2)
  }
  onClickTerlama(e) {
    e.preventDefault()
    this.props.onTerlama(2, 2)
  }
  handleNotifikasi(id, idUser, idPolicy) {
    if (id == 0) {
      return (
        <NavLink to={`/vehicle/polis/${idUser}/${idPolicy}`}><p className="notifikasi">Upload Polis!</p></NavLink>
      )
    }
    if (id == 1) {
      return (
        <NavLink to={`/vehicle/polis/${idUser}/${idPolicy}`}><p className="notifikasi">Need Endorsement!</p></NavLink>
      )
    }
    if (id == 2) {
      return (
        <p className="notifikasi">Polis Segera Habis!</p>
      )
    }
    if (id == 3) {
      return (
        <p className="notifikasi">Polis Telah Expired!</p>
      )
    }
    return ''
  }
  render() {
    const {
      sortBy
    } = this.props
    let listItems= []
    if(this.props && this.props.sortBy && !this.props.sortBy.message){
      listItems = Object.keys(this.props.sortBy).map(key => {
        return <tr key={key}>
          <td>
            <div className="image">
              { this.props.sortBy[key].avatar_url ? (
                <img className="img-polis" src={this.props.sortBy[key].avatar_url} alt="image"/>
              ) : (
                <img className="img-polis" src={notFound} alt="image" />
              )
              }
            </div>
          </td>
          <td>
            <NavLink to={"/vehicle/polis/" + this.props.sortBy[key].user_id}>
              <h3>{this.props.sortBy[key].full_name}</h3>
            </NavLink>
          </td>
          <td><p>{this.props.sortBy[key].last_order_date}</p></td>
          <td>{
            this.handleNotifikasi(
              this.props.sortBy[key].notification.id,
              this.props.sortBy[key].user_id,
              this.props.sortBy[key].notification.policy_id
            )
          }</td>
          <td className="right"><NavLink to={"/vehicle/polis/" + this.props.sortBy[key].user_id}>Detail</NavLink></td>
        </tr>

      })
    } else if (sortBy.message) {
      listItems = false
    }

    return (
      <div className="list-table">
        <table className="table">
          <thead>
            <th></th>
            <th>Nama Lengkap</th>
            <th>Tanggal Pembelian</th>
            {/* <th>Penerbit</th> */}
            <th>Notifikasi</th>
            <th className="right">
              <button id="Popover3" onClick={this.toggle}>
                     Urutkan dari <img src={bawah} />
              </button>
              <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover3"  toggle={this.toggle}>
                <PopoverBody>
                  <button onClick={this.onClickTerbaru}>Terbaru</button>
                  <button onClick={this.onClickTerlama}>Terlama</button>
                </PopoverBody>
              </Popover>
            </th>
          </thead>
          <tbody>
            {this.props.loading == true? "" : listItems}
          </tbody>
        </table>
        {
          sortBy.message && !this.props.loading ?
            (
              <div className="notFound">User tidak di temukan!</div>
            ) : (
              ''
            )
        }
        {this.props.loading == true? <Loading /> : ""}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    sortBy: state.sortPolis.sortBy,
    loading: state.sortPolis.loading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTerbaru: (sort, solution) => dispatch(actions.dataTerbaruPolis(sort, solution)),
    onTerlama: (sort, solution) => dispatch(actions.dataTerlamaPolis(sort, solution))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPolis)
