import React, {Component} from 'react' 
import {
  Col,
  Row,
  Container
} from 'reactstrap'
import FormLogin from './component/FormLogin'
import Header from './component/Header'
class Login extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="banner">
          <Container>
            <Row>
              <Col xl={{
                size:5,
                offset: 7
              }} md={{
                size: 7,
                offset: 5
              }}>
                <FormLogin />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}
export default Login
