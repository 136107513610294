import React, { Component } from 'react'
import jagadiri from "../../assets/img/insurance-logo-home-login.png"
import iconPolis from "../../assets/img/icon-polis.png"
import Banner from "../../assets/img/banner-home-vehicle-solution.png"
import Navigasi from "../Navigate/Navigasi"
import { NavLink } from "react-router-dom"
import Loading from '../loading'

class MenuVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.setState({
      loading: false
    })
  }
  render() {
    if(this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      return (
        <div>
          <Navigasi />
          <div className="banner-kategori">
            <div className="center">
              <img src={jagadiri} className="logo-jaga" />
            </div>
            <img src={Banner} className="banner-img" />
          </div>
          <div className="container">
            <div className="pilih">
              <ul className="row">
                {/*<li className="col-4">
                    <NavLink to={"/vehicle/produk"}>
                        <img src={iconProduk} />
                        <div className="icon-info">
                            <h3>Produk</h3>
                        </div>
                    </NavLink>
                </li>
                <li className="col-4">
                    <NavLink to="/vehicle/promosi">
                        <img src={iconPromosi} />
                        <div className="icon-info">
                            <h3>Promosi</h3>
                        </div>
                    </NavLink>
                </li>*/}
                <li className="col-4">
                  <NavLink to="/vehicle/polis">
                    <img src={iconPolis} />
                    <div className="icon-info">
                      <h3>Polis</h3>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default MenuVehicle
