import React, { Component } from 'react'
import iconPolis from "../../assets/img/icon-polis.png"
import Navigasi from '../Navigate/Navigasi'
import Banner from "../../assets/img/banner-home-travel-solution.png"
import { NavLink } from 'react-router-dom'

class MenuTravel extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="banner-kategori">
          <img src={Banner} className="banner-img" alt="banner" />
        </div>
        <div className="container">
          <div className="pilih">
            <ul className="row">
              {/*<li className="col-4">
                  <NavLink to="/travel/produk">
                      <img src={iconProduk} alt="icon-produk" />
                      <div className="icon-info">
                          <h3>Produk</h3>
                      </div>
                  </NavLink>
              </li>
              <li className="col-4">
                  <NavLink to="/travel/promosi" href="">
                      <img src={iconPromosi} alt="icon-promosi" />
                      <div className="icon-info">
                          <h3>Promosi</h3>
                      </div>
                  </NavLink>
              </li> */}
              <li className="col-4">
                <NavLink to="/travel/polis" href="">
                  <img src={iconPolis} alt="icon-polis" />
                  <div className="icon-info">
                    <h3>Polis</h3>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuTravel
