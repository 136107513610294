import React, { Component } from 'react'
import { connect } from 'react-redux'
import Peta from '../../assets/img/image-image.png'
import location from '../../assets/img/location.svg'
import operational from '../../assets/img/operational-hour.svg'
import call from '../../assets/img/call.svg'
import email from '../../assets/img/email.svg'
import baseurl from '../../helpers/constants'
import Navigasi from '../Navigate/Navigasi'
import * as actions from '../../store/actions/contactus'
import swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      about: {},
      order_id: '',
      message: '',
      subject: '',
      no_telepon: ''
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.alertSubmit = this.alertSubmit.bind(this)
  }
  componentDidMount() {
    fetch(`${baseurl.API}/v1/about`)
      .then(res => {
        return res.json()
      }).then(d => {
        this.setState({
          about: d
        })
      })
  }

  componentWillReceiveProps (nextProps) {
    const {
      contact
    } = this.props

    if(nextProps.contact !== contact) {
      this.alertSubmit(nextProps.contact)
    }
  }

  alertSubmit(data) {
    if (!data.loading && !data.error) {
      swal.fire({
        type: "success",
        title:	data.data.data.data.message,
        preConfirm: () => {
          window.location.reload()
        },
        allowOutsideClick: () => false,
        confirmButtonText: 'OK'
      })
    } else if (data.error){
      swal.fire({
        type: "error",
        title:	data.data.data.data.message,
        preConfirm: () => {
          window.location.reload()
        },
        allowOutsideClick: () => false,
        confirmButtonText: 'OK'
      })
    }
  }

  handleInput(e) {
    const { name, value } = e.target

    this.setState({
      [name]: value
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const {
      submitContact
    } = this.props

    const {
      order_id,
      message,
      subject,
      no_telepon
    } = this.state

    const param = {
      invoice_code: order_id,
      message,
      subject,
      no_telepon
    }

    submitContact(param)
  }

  render(){
    const {
      order_id,
      message,
      subject,
      no_telepon
    } = this.state
    return (
      <React.Fragment>
        <Navigasi />
        <div className="container">
          <div className="contactS">
            <div className="contact">
              <div className="row">
                <div className="col-md-6">
                  <h3>Tanya Pada Ahlinya</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <form
                    className="row"
                  >
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="input-middle"
                        placeholder="Order Id"
                        name="order_id"
                        value={order_id}
                        onChange={this.handleInput}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="input-middle"
                        placeholder="Telepon"
                        name="no_telepon"
                        value={no_telepon}
                        onChange={this.handleInput}
                      />
                    </div>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="input"
                        placeholder="Subject"
                        name="subject"
                        value={subject}
                        onChange={this.handleInput}
                      />
                    </div>
                    <div className="col-sm-12">
                      <textarea
                        placeholder="Pesan Anda"
                        name="message"
                        value={message}
                        onChange={this.handleInput}
                      />
                    </div>
                    <div className="col-sm-12">
                      <div className="tengah">
                        <button onClick={this.handleSubmit}>
												Kirim
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  <div className="peta">
                    <img src={Peta} className="peta-img"/>

                    <div className="peta-info">
                      <ul>
                        <li>
                          <div className="row">
                            <div className="col-sm-1">
                              <img src={location} />
                            </div>
                            <span className="col-sm-10">{this.state.about.address}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-sm-1">
                              <img src={operational} />
                            </div>
                            <span className="col-sm-10">(Mon - Fri) {this.state.about.operational_hour}</span>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-sm-1">
                              <img src={call} />
                            </div>
                            <span className="col-sm-10">{this.state.about.phone_number}</span>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-sm-1">
                              <img src={email} />
                            </div>
                            <span className="col-sm-10">{this.state.about.email}</span>
                          </div>
                        </li>
                      </ul>
                      <div className="get">
                        <h4>Get Direction</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    contact: state.contact
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submitContact: (params) => dispatch(actions.contactKirim(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
