import React, { Component } from 'react'
import jp_vehicle from '../../assets/img/jp-travel.png'
import FormPolis from './component/FormPolis'
import ListPolis from './component/ListPolis'
import Navigasi from '../Navigate/Navigasi'

class PolisTravel extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="judul-travel">
          <h3>Polis</h3><img src={jp_vehicle} className="img-judul" alt="" />
        </div>
        <div className="polis-info">
          <div className="container">
            <FormPolis />
            <ListPolis />
          </div>
        </div>
      </div>
    )
  }
}

export default PolisTravel
