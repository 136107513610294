import React, { Component } from 'react'
import jp_vehicle from '../../assets/img/jp-automotive-se.png'
import FormPromosi from './component/FormPromosi'

class PromosiVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  render(){
    return (
      <div className="banner-vehicle">
        <div className="judul-vehicle">
          <h3>Promosi Anda</h3><img src={jp_vehicle} className="img-judul" alt="" />
        </div>
        <div className="produk-info">
          <div className="container">
            <FormPromosi />
          </div>
        </div>
      </div>
    )
  }
}

export default PromosiVehicle
