import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ListProduk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      judul: "Jaga Sehat Plus",
      list: [
        "Rawat Inap dan Pembedahan",
        "Rawat Jalan",
        "Cashless dan Reimbuse",
        "Gratis Asuransi Kecelakaan Diri",
        "Bebas Memilih Rumah Sakit"
      ]
    }
  }

  render() {
    const listItems = this.state.list.map((list) =>
      <li key={list.toString()}>
        {list}
      </li>
    )
    return (
      <div className="list">
        <div className="row">
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="produk">
              <div className="judul-list">
                <h3>{this.state.judul}</h3>
              </div>
              <div className="list-items">
                <ul>
                  {listItems}
                </ul>
              </div>
              <div className="lengkap">
                <Link to="/ProductContentPage">Selengkapnya</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ListProduk
