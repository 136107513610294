import React, { Component } from 'react'
import { Collapse } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import Logo from "../assets/img/logo-jagadiri.svg"
import bawah from '../assets/img/arrow-down-blue.svg'
import Loading from './loading'
import baseurl from '../helpers/constants'

class ProdukContentPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: ['in'],
      loading: true,
      collapse:true,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      collapse5: false,
      collapse6: false
    }
    this.toggle = this.toggle.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)
    this.toggle4 = this.toggle4.bind(this)
    this.toggle5 = this.toggle5.bind(this)
    this.toggle6 = this.toggle6.bind(this)
  }
  toggle() {
    this.setState({
      collapse: !this.state.collapse
    })
  }
  toggle2() {
    this.setState({
      collapse2: !this.state.collapse2
    })
  }
  toggle3() {
    this.setState({
      collapse3: !this.state.collapse3
    })
  }
  toggle4() {
    this.setState({
      collapse4: !this.state.collapse4
    })
  }
  toggle5() {
    this.setState({
      collapse5: !this.state.collapse5
    })
  }
  toggle6() {
    this.setState({
      collapse6: !this.state.collapse6
    })
  }


  componentDidMount () {
    // console.log("componentDidMount")
    // dispatch(sortStart());
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    // const sortBySearch = {
    //     sort: search
    // }
    axios.get(`${baseurl.API}/v1/product/vehicle_detail/` + this.props.match.params.id)
      .then(res => {
        // console.log(res);
        const data = res.data.data
        this.setState ({
          data : data,
          loading: false
        })
        // console.log(data);
        // console.log(data.data);
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    // console.log('ini', this.state.data)
    // console.log('loading', this.state.loading);
    let itemList = []
    if(this.state && this.state.data.benefit) {
      itemList = Object.keys(this.state.data.benefit).map(key => {
        return (
          <div key={key}>{this.state.data.benefit[key].benefit_title}</div>
        )
      })
    }
    if (this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      return (
        <div className="produk-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="back">
                  <NavLink to="/vehicle/produk"><button type="button" className="back-info">BACK</button></NavLink>
                </div>
              </div>
              <div className="col-md-4">
                <div className="side-bar">
                  <ul>
                    <li>
                      <img src={Logo} alt="logo" />
                    </li>
                    <li><p>{this.state.data.premi.title}</p></li>
                    <li><span>{this.state.data.premi.total_insured}</span></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content">
                  <h3>{this.state.data.publisher.title}</h3>
                  <div className="box-content">
                    <div className="judul-content">
                      <h3>Deskripsi Produk</h3>
                      <img src={bawah} alt="sdsd" className="pointer1" onClick={this.toggle}/>
                    </div>
                    <Collapse isOpen={this.state.collapse}>

                      <div className={'isi-content content-down1 '}>
                        {this.state.data.premi.premium_description}
                      </div>
                    </Collapse>
                  </div>
                  {/* <div className="box-content margin ">
                                        <div className="judul-content">
                                            <h3>Jumlah Rumah Sakit Rekanan</h3>
                                            <img src={bawah} alt="sdsd" className="pointer2" />
                                        </div>
                                        <div className="isi-content content-down2">
                                            <p>Simas Sehat Executive adalah produk kesehatan
                                                dari PT. Asuransi Sinarmas yang memberikan perlindungan untuk:</p>
                                            <ol>
                                                <li>Biaya kamar dan menginap s/d 365 hari</li>
                                                <li>Biaya kamar ICU/ICCU, perhari s/d 60 hari</li>
                                                <li>Biaya aneka perawatan rumah sakit</li>
                                                <li>Biaya operasi/pembedahan termasuk biaya pembiusan dan biaya kamar bedah</li>
                                                <li>Biaya kunjungan Dokter di rumah sakit</li>
                                                <li>Biaya kunjungan Dokter ahli di rumah sakit</li>
                                                <li>Biaya pemeriksaan lab & test diagnostik 7 hari sebelum perawatan inap di rumah sakit
            </li>
                                                <li>Biaya konsultasi lanjutan s/d 60 hari setelah lepas rawat</li>
                                                <li>Biaya transportasi mobil ambulance menuju rumah sakit (dalam keadaaan darurat)</li>
                                                <li>Biaya operasi/bedah plastik akibat kecelakaan</li>
                                                <li>Penggantian biaya perawatan transplantasi organ tubuh (jantung, hati, paru-paru ginjal dan sumsum tulang), batas maksimum penggantian per tahun</li>
                                            </ol>
                                        </div>
                                    </div> */}
                  <div className="box-content margin">
                    <div className="judul-content">
                      <h3>Manfaat Produk</h3>
                      <img src={bawah} alt="sdsd" className="pointer3" onClick={this.toggle2}/>
                    </div>
                    <Collapse isOpen={this.state.collapse2}>
                      <div className="isi-content content-down3">
                        {itemList}
                      </div>
                    </Collapse>
                  </div>
                  {/* <div className="box-content margin">
                                        <div className="judul-content">
                                            <h3>Penjelasan Produk</h3>
                                            <img src={bawah} alt="sdsd" className="pointer4" />
                                        </div>
                                        <div className="isi-content content-down4">
                                            <p>Simas Sehat Executive adalah produk kesehatan
                                                dari PT. Asuransi Sinarmas yang memberikan perlindungan untuk:</p>
                                            <ol>
                                                <li>Biaya kamar dan menginap s/d 365 hari</li>
                                                <li>Biaya kamar ICU/ICCU, perhari s/d 60 hari</li>
                                                <li>Biaya aneka perawatan rumah sakit</li>
                                                <li>Biaya operasi/pembedahan termasuk biaya pembiusan dan biaya kamar bedah</li>
                                                <li>Biaya kunjungan Dokter di rumah sakit</li>
                                                <li>Biaya kunjungan Dokter ahli di rumah sakit</li>
                                                <li>Biaya pemeriksaan lab & test diagnostik 7 hari sebelum perawatan inap di rumah sakit
            </li>
                                                <li>Biaya konsultasi lanjutan s/d 60 hari setelah lepas rawat</li>
                                                <li>Biaya transportasi mobil ambulance menuju rumah sakit (dalam keadaaan darurat)</li>
                                                <li>Biaya operasi/bedah plastik akibat kecelakaan</li>
                                                <li>Penggantian biaya perawatan transplantasi organ tubuh (jantung, hati, paru-paru ginjal dan sumsum tulang), batas maksimum penggantian per tahun</li>
                                            </ol>
                                        </div>
                                    </div> */}
                  <div className="box-content margin">
                    <div className="judul-content">
                      <h3>Syarat dan Ketentuan</h3>
                      <img src={bawah} alt="sdsd" className="pointer3" onClick={this.toggle3} />
                    </div>
                    <Collapse isOpen={this.state.collapse3}>
                      <div className="isi-content content-down3">
                        {this.state.data.premi.term_and_condition}
                      </div>
                    </Collapse>
                  </div>
                  <div className="box-content margin">
                    <div className="judul-content">
                      <h3>Cara Klaim Asuransi</h3>
                      <img src={bawah} alt="sdsd" className="pointer3" onClick={this.toggle4} />
                    </div>
                    <Collapse isOpen={this.state.collapse4}>
                      <div className="isi-content content-down3">
                        {this.state.data.premi.claim_step}
                      </div>
                    </Collapse>
                  </div>
                  <div className="box-content margin">
                    <div className="judul-content">
                      <h3>Pengecualian Manfaat Asuransi</h3>
                      <img src={bawah} alt="sdsd" className="pointer3" onClick={this.toggle5} />
                    </div>
                    <Collapse isOpen={this.state.collapse5}>
                      <div className="isi-content content-down3">
                        {this.state.data.premi.premium_exception}
                      </div>
                    </Collapse>
                  </div>
                  <div className="box-content margin">
                    <div className="judul-content">
                      <h3>Informasi Kontak</h3>
                      <img src={bawah} alt="sdsd" className="pointer3" onClick={this.toggle6} />
                    </div>
                    <Collapse isOpen={this.state.collapse6}>
                      <div className="isi-content content-down3">
                        {this.state.data.premi.contact}
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      )
    }
  }
}

export default ProdukContentPage
