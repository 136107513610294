import React, { Component } from 'react'
import jp_health from '../../assets/img/jp-automotive-se.png'
import FormPolis from './component/FormPolis'
import ListPolis from './component/ListPolis'
import Navigasi from '../Navigate/Navigasi'
import Loading from '../loading'
class PolisVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    this.setState({
      loading: false
    })
  }
  render() {
    console.log(this.state.loading)
    if(this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      return (
        <div>
          <Navigasi />
          <div className="judul-vehicle">
            <h3>Polis</h3><img src={jp_health} className="img-judul" alt="" />
          </div>
          <div className="polis-info">
            <div className="container">
              <FormPolis />
              <ListPolis />
            </div>
          </div>
        </div>
      )
    }
  }
}

export default PolisVehicle
