import React, { Component } from 'react'
import {
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'
import PopoverNot from './component/PopoverNot'
import PopoverProf from './component/PopoverProf'

export default class Navigasi extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      loader: false,
      menu1: {},
      menu2: {},
      menu3: {},
      menu4: {}
    }
  }
  toggleNav() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  componentDidMount() {
    console.log("Compon")
    this.setState({
      loader: false
    })
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render(){
    var x = window.matchMedia("(max-width: 700px)")
    if (x.matches) {
      return (
        <div className="nav">
          <Container>
            <Row>
              <Col xs="6" sm="6">
                <div className="brand">
                  <NavLink to="/"><img src={logo} className="logo" alt="log" /></NavLink>
                </div>
              </Col>
              <Col xs="6" sm="6">
                <div className="akun">
                  <ul>
                    <li>
                      <PopoverNot />
                    </li>
                    <li>
                      <PopoverProf />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="1" sm="12">
                <div className="navigation">
                  <Nav>
                    <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleNav}>
                      <DropdownToggle nav caret>
                                                Solution
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul>
                          <li><NavLink to="/personal">Personal Solution</NavLink></li>
                          <li><NavLink to="/vehicle">Vehicle Solution</NavLink></li>
                          <li><NavLink to="/property">Property Solution</NavLink></li>
                          <li><NavLink to="/travel">Travel Solution</NavLink></li>
                        </ul>
                      </DropdownMenu>
                    </Dropdown>
                    <li>
                      <NavLink to="/contact" className="nav-link">Hubungi Kami</NavLink>
                    </li>
                  </Nav>
                </div>
              </Col>

            </Row>
          </Container>
        </div>
      )
    } else {
      return (
        <div className="nav">
          <Container>
            <Row>
              <Col xs="6" sm="3">
                <div className="brand">
                  <NavLink to="/"><img src={logo} className="logo" alt="log" /></NavLink>
                </div>
              </Col>
              <Col xs="1" sm="6">
                <div className="navigation">
                  <Nav>
                    <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleNav}>
                      <DropdownToggle nav caret>
                                                Solution
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul>
                          <li><NavLink to="/personal">Personal Solution</NavLink></li>
                          <li><NavLink to="/vehicle">Vehicle Solution</NavLink></li>
                          <li><NavLink to="/property">Property Solution</NavLink></li>
                          <li><NavLink to="/travel">Travel Solution</NavLink></li>
                        </ul>
                      </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                      <NavLink to="/contact" className="nav-link">Hubungi Kami</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col xs="5" sm="3">
                <div className="akun">
                  <ul>
                    <li>
                      <PopoverNot />
                    </li>
                    <li>
                      <PopoverProf />
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  }
}
