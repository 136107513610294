import React from 'react'
import ReactLoading from 'react-loading'
 
const Loading = () => (
  <div className="center-left">
    <ReactLoading type="spinningBubbles" color="#212529" height={200} width={100} />
  </div>
)
 
export default Loading
