import React, { Component } from 'react'
import arrowr from "../assets/img/arrow-r.svg"
import notFound from "../assets/img/profile-icon.png"
import Navigasi from "./Navigate/Navigasi.js"
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import axios from 'axios'
import { Link } from 'react-router-dom'
import baseurl from '../helpers/constants'

class ProfilePolis extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '2',
      data: [],
      dataPolis: [],
      solution: ''

    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  componentDidMount() {
    // console.log("componentDidMount")
    // dispatch(sortStart());
    const {
      match
    } = this.props

    console.log('asd', match)
    const getSolution = match.path.split('/')
    console.log('asd', getSolution)
    let solution = ''
    switch (getSolution[1]) {
    case 'personal':
      solution = 1
      break
    case 'vehicle':
      solution = 2
      break
    case 'property':
      solution = 3
      break
    case 'travel':
      solution = 4
      break
    }

    this.setState({
      solution: getSolution[1]
    })

    console.log('asd', solution)
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    // const sortBySearch = {
    //     sort: search
    // }
    axios.get(`${baseurl.API}/v1/vendor/profile_user/` + this.props.match.params.id)
      .then(res => {
        // console.log(res);
        const data = res.data.data
        this.setState({
          data: data,
          loading: false
        })
        // console.log(data);
        // console.log(data.data);
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`${baseurl.API}/v1/vendor/policy_user/${solution}/` + this.props.match.params.id)
      .then(res => {
        // console.log(res);
        const dataPolis = res.data.data
        this.setState({
          dataPolis: dataPolis,
          loading: false
        })
        // console.log(data);
        // console.log(data.data);
        // this.setState({ lists : data.data})
      })
      .catch(err => {
        console.log(err)
      })
  }

  onBack () {
    window.history.back()
  }

  render() {
    // let itemList = [];
    const {
      solution
    } = this.state
    let listItems= []
    if (this.state && this.state.dataPolis) {
      listItems = Object.keys(this.state.dataPolis).map(key => {
        return <tr key={key}>
          <td>{this.state.dataPolis[key].policy_number}</td>
          {/* <td>-</td> */}
          <td>{this.state.dataPolis[key].insurance_title}</td>
          <td>{this.state.dataPolis[key].status == 1 ?
            (<div className="notifikasiBlack">Aktif</div>) :
            (<div className="notifikasiRed">Tidak Aktif</div>)
          }</td>
          <td className="center"><Link to={`/${solution}/polis/`+this.props.match.params.id+`/` + this.state.dataPolis[key].policy_id}><button><img src={arrowr} alt="lanjut" /></button></Link></td>
        </tr>

      })
    }
    // if(this.state && this.state.dataPolis) {
    //     itemList = Object.keys(this.state.dataPolis).map(key => {
    //         return
    //             <tr key={key}>
    //                 <td>{this.state.dataPolis[key].insurance_policy_number}</td>
    //                 <td>-</td>
    //                 <td>-</td>
    //                 <td>{this.state.dataPolis[key].policy_status}</td>
    //                 <td className="center"><Link to={"`/vehicle/polis/`+this.props.match.params.id+`/Asuransi`}><button><img src={arrowr} alt="lanjut" /></button></Link></td>
    //             </tr>

    //     })
    // }

    return (
      <div>
        <Navigasi />
        <div className="profil-user">
          <div className="container">
            <Nav tabs>
              {/* <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1') }}
                            >
                                <button>Profil</button>
                            </NavLink>
                        </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2') }}
                >
                  <button>Asuransi</button>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="photo-profil">
                      {
                        this.state.data.avatar_url ?
                          (
                            <img className="image-profile" src={this.state.data.avatar_url} alt="" />
                          ) : (
                            <img className="image-profile" src={notFound} alt="" />
                          )
                      }

                    </div>
                  </div>
                  <div className="col-sm-10">
                    <h3>{this.state.data.full_name}</h3>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <h4>Tempat Lahir</h4>
                        <p>{
                          this.state.data.birth_place ?
                            this.state.data.birth_place : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Tanggal Lahir</h4>
                        <p>{
                          this.state.data.birth_date ?
                            this.state.data.birth_date : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Jenis Kelamin</h4>
                        <p>{this.state.data.gender ?
                          this.state.data.gender : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Pekerjaan</h4>
                        <p>{this.state.data.jobs ?
                          this.state.data.jobs : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor KTP/NIK/KITAS/SIM</h4>
                        <p>{this.state.data.identity_number ?
                          this.state.data.identity_number : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Nomor NPWP</h4>
                        <p>{this.state.data.npwp_number ?
                          this.state.data.npwp_number : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-12">
                        <h2>Alamat Sesuai KTP</h2>
                        <h4>Alamat Lengkap</h4>
                        <p>{this.state.data.address ?
                          this.state.data.address : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Provinsi</h4>
                        <p>{this.state.data.province ?
                          this.state.data.province : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kota</h4>
                        <p>{this.state.data.city ?
                          this.state.data.city : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kecamatan</h4>
                        <p>{this.state.data.district ?
                          this.state.data.district : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kelurahan</h4>
                        <p>{this.state.data.village ?
                          this.state.data.village : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kode Pos</h4>
                        <p>{this.state.data.postal_code ?
                          this.state.data.postal_code : '-'
                        }</p>
                        <hr />
                      </div><div className="col-sm-12">
                        <h2>Alamat Korespondensi</h2>
                        <h4>Alamat Lengkap</h4>
                        <p>{this.state.data.address ?
                          this.state.data.address : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Provinsi</h4>
                        <p>{this.state.data.province ?
                          this.state.data.province : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kota</h4>
                        <p>{this.state.data.city ?
                          this.state.data.city : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kecamatan</h4>
                        <p>{this.state.data.district ?
                          this.state.data.district : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kelurahan</h4>
                        <p>{this.state.data.village ?
                          this.state.data.village : '-'
                        }</p>
                        <hr />
                      </div>
                      <div className="col-sm-4">
                        <h4>Kode Pos</h4>
                        <p>{this.state.data.postal_code ?
                          this.state.data.postal_code : '-'
                        }</p>
                        <hr />
                      </div>

                      <div className="number">
                        <div className="row">
                          <div className="col-sm-4">
                            <h4>Nomor Telepon Rumah</h4>
                            <p>{this.state.data.phone_number? this.state.data.phone_number : '-'}</p>
                            <hr />
                          </div>
                          <div className="col-sm-4">
                            <h4>Nomor HP</h4>
                            <p>{this.state.data.mobile_number ?
                              this.state.data.mobile_number : '-'
                            }</p>
                            <hr />
                          </div>
                          <div className="col-sm-4">
                            <h4>Email</h4>
                            <p>{this.state.data.email ?
                              this.state.data.email : '-'
                            }</p>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </TabPane>
              <TabPane tabId="2">
                <div className="tabel-asuransi">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nomor Polis</th>
                        {/* <th>Penerbit</th> */}
                        <th>Produk Asuransi</th>
                        <th className="center">Status Polis</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listItems}
                      {/* <tr>
                                            <td className="bold">1889371991010</td>
                                            <td>CHUBB</td>
                                            <td><span>Personal Health</span> Hospital Cash Plan 5 Diseases - Medicillin Cash Plan 5 Diseases</td>
                                            <td>Tidak Aktif</td>
                                            <td className="center"><Link to={`/vehicle/polis/`+this.props.match.params.id+`/Asuransi`}><button><img src={arrowr} alt="lanjut" /></button></Link></td>
                                        </tr>
                                        <tr>
                                            <td className="bold">1889371991010</td>
                                            <td>CHUBB</td>
                                            <td><span>Travel International</span> Family</td>
                                            <td>Tidak Aktif</td>
                                            <td className="center"><Link to="/vehicle/polis/2/Asuransi"><button><img src={arrowr} alt="lanjut" /></button></Link></td>
                                        </tr>
                                        <tr>
                                            <td className="bold">1889371991010</td>
                                            <td>CHUBB</td>
                                            <td><span>Vehicle Car</span> All Risk</td>
                                            <td>Tidak Aktif</td>
                                            <td className="center"><Link to="/vehicle/polis/3/Asuransi"><button><img src={arrowr} alt="lanjut" /></button></Link></td>
                                        </tr>
                                        <tr>
                                            <td className="bold">1889371991010</td>
                                            <td>CHUBB</td>
                                            <td><span>Property Value</span> Plan B</td>
                                            <td>Tidak Aktif</td>
                                            <td className="center"><Link to="/vehicle/polis/4/Asuransi"><button><img src={arrowr} alt="lanjut" /></button></Link></td>
                                        </tr> */}
                    </tbody>
                  </table>
                </div>
              </TabPane>
            </TabContent>
            <div className="col-md-12">
              <div className="back">
                <button type="button" onClick={this.onBack} className="back-info">BACK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfilePolis
