import React, { Component } from 'react'
import Navigasi from '../Navigate/Navigasi'
import iconPolis from "../../assets/img/icon-polis.png"
import Banner from "../../assets/img/banner-home-property-solution.png"

import { NavLink } from 'react-router-dom'

class MenuProperty extends Component {
  render() {
    return (
      <div>
        <Navigasi />
        <div className="banner-kategori">
          <img src={Banner} className="banner-img" alt="banner" />
        </div>
        <div className="container">
          <div className="pilih">
            <ul className="row">
              {/*<li className="col-4">
                  <NavLink to="/property/produk">
                      <img src={iconProduk} alt="" />
                      <div className="icon-info">
                          <h3>Produk</h3>
                      </div>
                  </NavLink>
              </li>
              <li className="col-4">
                  <NavLink to="/property/promosi">
                      <img src={iconPromosi} alt="" />
                      <div className="icon-info">
                          <h3>Promosi</h3>
                      </div>
                  </NavLink>
              </li>*/}
              <li className="col-4">
                <NavLink to="/property/polis">
                  <img src={iconPolis} alt="" />
                  <div className="icon-info">
                    <h3>Polis</h3>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuProperty
