import * as actionTypes from '../actions/actionTypes'
import {
  updateObject
} from '../utility'

const initialState = {
  data: [],
  sortBy: [],
  loading: false,
  error: null,
  sortName: "Terbaru"
}
const sortStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}
const sortSucces = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sortBy: action.sortPolis,
    loading: false
  })
}
const sortTerbaru = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sortBy: action.sortPolis,
    loading: false,
    sortName: "Terbaru"
  })
}
const sortTerlama = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sortBy: action.sortPolis,
    loading: false,
    sortName: "Terlama"
  })
}

const sortSearchPolis = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sortBy: action.sortPolis,
    loading: false,
    sortName: "Terlama"
  })
}

const sortPolisReducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SORT_BY_TERBARU:
    return sortTerbaru(state, action)
  case actionTypes.SORT_SUCCES:
    return sortSucces(state, action)
  case actionTypes.SORT_BY_TERLAMA:
    return sortTerlama(state, action)
  case actionTypes.SORT_START:
    return sortStart(state, action)
  case actionTypes.SORT_SEARCH_POLIS:
    return sortSearchPolis(state, action)
  default:
    return state
  }
}

export default sortPolisReducer
