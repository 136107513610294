import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Loading from '../../loading'
import * as actions from '../../../store/actions/sort'

class ListProduk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lists:{},
      totalPage: this.props.sortBy
    }
    this.handleClickPagination = this.handleClickPagination.bind(this)
  }

  componentDidMount() {
    let listPagination = []

    for (var i=1; i <= this.props.sortBy.total_page; i++) {
      listPagination.push(i)
    }
    console.log('zilmas', this.props.sortBy)

    this.setState({
      lists: this.props.list,
      totalPage: listPagination
    })
  }

  componentWillReceiveProps() {
    console.log('props', this.props.sortBy.total_page)

    let listPagination = []
    for (var i = 1; i <= this.props.sortBy.total_page; i++) {
      listPagination.push(i)
    }

    this.setState({
      lists: this.props.list,
      totalPage: listPagination
    })
  }

  handleClickPagination (event) {
    const { sortName } = this.props
    const id = event.target.id

    switch (sortName) {
    case 'Terbaru':
      this.props.onTerbaru(1, id)
      break
    case 'Terlama':
      this.props.onTerlama(2, id)
      break
    }
  }

  render() {
    // const { totalPage } = this.state
    const { sortName } = this.props

    console.log('this', sortName)

    const pageNumber = []
    for (let i = 1; i<= 3; i++) {
      pageNumber.push(i)
    }
    const renderPageNumber = pageNumber.map(number => {
      return (
        <li 
          key={number}
          id={number}
          onClick={this.handleClickPagination}
        > {number} 
        </li>
      )
    })
    // this.setState({
    //     totalPage: this.
    // })
    var products = this.props.list.products
        
    let listItems=null
    if(products){
      console.log('ini product',products)
      listItems = products.map((list, key) => {
        console.log(key)
        if (products) {
          return <div className="col-md-4" key = {key} > 
            <div className="produk">
              <div className="judul-list">
                <h3>{products[key]? products[key].title : 'gak ada'}</h3>
              </div>
              <div className="list-items">
                <ul>
                  { products[key].benefit ? products[key].benefit.map((id, list) => 
                    <li key={list}>{
                      products[key] ? products[key].benefit[list].benefit_title : 'gak ada'
                    } 
                    </li>
                  ) : ''
                  }
                </ul>
              </div>
              <div className="lengkap">
                <Link to={"/vehicle/produk/" + products[key].id}>Selengkapnya</Link>
              </div>
            </div>
          </div>
        }
      })
    } else if (this.listItems === null) {
      listItems = <div>Data yang ingin anda cari tidak ada</div>
            
    }
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
    
      return (
        <div className="list">
          <div className="row">
            {
              listItems
            }
            <div className="col-md-12"> 
              <div className="button">
                <ul>
                  {
                    renderPageNumber
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    loading: state.sort.loading,
    error: state.auth.error,
    sortBy: state.sort.sortBy,
    sortName: state.sort.sortName
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTerbaru: (sort, id) => dispatch(actions.dataTerbaru(sort, id)),
    onTerlama: (sort, id) => dispatch(actions.dataTerlama(sort, id)),
    onSearch: (search) => dispatch(actions.dataSearch(search))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProduk)
