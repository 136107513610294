import React, { Component } from 'react'
import notFound from "../../../assets/img/profile-icon.png"
import Navigasi from "../../Navigate/Navigasi"
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import {Button, Modal, ModalBody} from 'reactstrap'
import axios from 'axios'
import Loading from '../../loading'
import UploadFile from './UploadFile'
import baseurl from '../../../helpers/constants'

class AsuransiPolisPersonal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '2',
      modal: false,
      dataPolis: [],
      benefit: [],
      Endorsement: [],
      loader1: false,
      loader2: false,
      dataProfile: {}
    }
    this.toggle = this.toggle.bind(this)
    this.toggleB = this.toggleB.bind(this)
    this.download = this.download.bind(this)
    this.convertToRupiah = this.convertToRupiah.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
	
  download () {
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    axios.get(`${baseurl.API}/v1/vendor/download_policy_as_excel/2/${this.props.match.params.ids}/xlsx`).then(() => {}).catch(() => {})
  }

  toggleB() {
    this.setState({
      modal: !this.state.modal
    })
  }

  convertToRupiah(val) {
    const numberString = val.toString()
    const sisa = numberString.length % 3
    let rupiah = numberString.substr(0, sisa)
    const ribuan = numberString.substr(sisa).match(/\d{3}/g)
    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }
    return `Rp ${rupiah}`
  }

  componentDidMount() {
    const {
      match
    } = this.props

    const getSolution = match.path.split('/')
    let solution = ''
    switch (getSolution[1]) {
    case 'personal':
      solution = 1
      break
    case 'vehicle':
      solution = 2
      break
    case 'property':
      solution = 3
      break
    case 'travel':
      solution = 4
      break
    }
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    axios.get(`${baseurl.API}/v1/vendor/detail_policy_user/${solution}/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader1: true,
          dataPolis: dataPolis,
          benefit: dataPolis.benefit_premia
        })
      })
      .catch(() => {
      })

    axios.get(`${baseurl.API}/v1/endorsement_status/` + this.props.match.params.ids)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loader2: true,
          Endorsement: dataPolis
        })
      })
      .catch(() => {
      })
  }

  render() {
    const {
      dataPolis,
      loader1,
      loader2
    } = this.state
    if(!(loader1 && loader2)){
      return (
        <div>
          <Navigasi />
          <div className="profil-user">
            <div className="container">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2') }}
                  >
                    <button>Asuransi</button>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="2">
                  <Loading />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Navigasi />
        <div className="profil-user">
          <div className="container">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2') }}
                >
                  <button>Asuransi</button>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="2">
                <div className="asuransi-info">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="photo-polis">
                        {
                          dataPolis.publisher_logo ?
                            (
                              <img className="image-profile" src={dataPolis.publisher_logo} alt="" />
                            ) : (
                              <img className="image-profile" src={notFound} alt="" />
                            )
                        }
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="row order-info">
                        <div className="informasi">
                          <div className="informasiDiri">
                            <div className="judulPolis">
                              <h3>Informasi Data Diri</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="judul-box">
                                  <h4>Alamat Lengkap Sesuai KTP</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.address_detail : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Provinsi</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.province : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kota</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.city : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kecamatan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.district : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kelurahan</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.village : '-'}</p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="judul-box">
                                  <h4>Kode Pos</h4>
                                </div>
                                <div className="data-box">
                                  <p>{dataPolis.profile ? dataPolis.profile.postal_code : '-'}</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="informasiDiri">
                            <div className="judulPolis">
                              <h3>Informasi Peserta Polis</h3>
                            </div>
                            <div className="subJudul">
                              <h3>Ahli Waris</h3>
                            </div>
                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Nama Lengkap</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.personal.heirs.name_of_heir ? dataPolis.personal.heirs.name_of_heir : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Hubungan</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.personal.heirs.relation_of_heir ? dataPolis.personal.heirs.relation_of_heir : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>Tanggal Lahir</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.personal.heirs.birth_date_of_heir ? dataPolis.personal.heirs.birth_date_of_heir : '-'}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="judul-box">
                                      <h4>No Passpor/KTP</h4>
                                    </div>
                                    <div className="data-box">
                                      <p>{dataPolis.personal.heirs.identity_number_of_heir ? dataPolis.personal.heirs.identity_number_of_heir : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="simpan simpanxl-personal">
                                  <a href={`${baseurl.API}/v1/vendor/download_policy_as_excel/1/${this.props.match.params.ids}/xlsx`} className="back-info">Simpan .xls</a>
                                </div>
                                <div className="simpan simpan-personal">
                                  <a href={`${baseurl.API}/v1/vendor/download_policy_user/1/${this.props.match.params.ids}`} className="back-info">Simpan .pdf</a>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Nama Produk</h4>
                          <p>{dataPolis.insurance_product}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Masa Berlaku</h4>
                          <p>{dataPolis.validity_period}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Nomor Polis</h4>
                          <p>{dataPolis.policy_number ? dataPolis.policy_number : '-'}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Status Polis</h4>
                          <p>{dataPolis.status}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Jenis Perlindungan</h4>
                          <p>{dataPolis.protection_type ? dataPolis.protection_type : '-'}</p>
                        </div>
                        <div className="asuransi col-md-4">
                          <h4>Kategori</h4>
                          <p>{dataPolis.personal ? dataPolis.personal.category : '-'}</p>
                        </div>
                        <div className="asuransi col-md-2">
                          <h4>Total Premi</h4>
                          <p className="redFont">{dataPolis.grand_total ? this.convertToRupiah(parseInt(dataPolis.grand_total)) : "-"}</p>
                        </div>
                      </div>
                      <div className="asuransi">
                        <h4>Manfaat Produk</h4>
                        <ol>
                          {dataPolis.benefit_premia && dataPolis.benefit_premia.map((data, key) => {
                            return <li key={key}>{data.title}</li>
                          })}
                        </ol>
                      </div>
                      <div className="upload">
                        <Button onClick={this.toggleB}>Upload Polis</Button>
                        <Modal isOpen={this.state.modal} toggle={this.toggleB} size="700px" className="modal-upload">
                          <ModalBody>
                            <div className="upload">
                              <div className="container">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <UploadFile url={this.props.match.params.ids} number={dataPolis.policy_number}/>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                      <div className="status">
                        <h5>Endorsement Status</h5>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nomor</th>
                              <th>Keterangan</th>
                              <th>File</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.Endorsement.length >= 1 ? this.state.Endorsement.map((data, key) => {
                              let str = data.file_name
                              return <tr key={key}>
                                <td>{dataPolis.policy_number}</td>
                                <td>{data.description}</td>
                                <td><a href={`${data.file_path}`}>{str}</a></td>
                              </tr>
                            }) : <tr><td colSpan="3" className="redFont text-center">Data Endorsement Kosong, Silahkan Upload Polis.</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}
export default AsuransiPolisPersonal
