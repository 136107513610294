export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const CHANGE_SANDI = 'CHANGE_SANDI'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const SORT_BY_TERBARU = 'SORT_BY_TERBARU'
export const SORT_BY_TERLAMA = 'SORT_BY_TERLAMA'
export const SORT_START = 'SORT_START'
export const SORT_SEARCH = 'SORT_SEARCH'
export const SORT_SEARCH_POLIS = 'SORT_SEARCH_POLIS'

export const CONTACT_START = 'CONTACT_START'
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_FAIL = 'CONTACT_FAIL'
