import React, { Component } from 'react'
import {
  Row,
  Col,
  Container
} from 'reactstrap'
import logo from '../../assets/img/group.png'
import facebook from '../../assets/img/facebook.svg'
import twitter from '../../assets/img/twitter.svg'
import youtube from '../../assets/img/youtube.svg'
import instagram from '../../assets/img/intagram.svg'
import ReactHtmlParser from 'react-html-parser'

import baseurl from '../../helpers/constants'


class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      about : {}
    }
  }
  componentDidMount() {
    fetch(`${baseurl.API}/v1/about`)
      .then(res => {
        return res.json()
      }).then(d => {

        this.setState({
          about: d
        })
      })
  }
  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <a href="">
                  <img src={logo} alt="" />
                </a>
              </div>
              <div className="alamat">
                <p>{ReactHtmlParser(this.state.about.address)}</p>
              </div>
            </Col>
            <Col md="3">
              <div className="judul_kontak">
                <h3>Kontak Kami</h3>
              </div>
              <div className="info_kontak">
                <ul>
                  <li>
                    <span>Telepon: </span>{this.state.about.phone_number}
                  </li>
                  <li>
                    <span>Operational Hour: </span>(Mon - Fri) {this.state.about.operational_hour}
                  </li>
                  <li>
                    <span>Email: </span>{this.state.about.email}
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="judul_support">
                <h3>Dukungan Pelanggan</h3>
              </div>
              <div className="info_support">
                <a href="#sdsd">Term & Condition</a>
                <a href="#sd">Privacy Policy</a>
              </div>
            </Col>
            <Col md="3">
              <div className="judul_support_media">
                <h3>Ikuti Kami di Social Media</h3>
              </div>
              <div className="info_media">
                <ul>
                  <li>
                    <a href="#dsd">
                      <img src={facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#dsd">
                      <img src={twitter} alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#dsd">
                      <img src={instagram} alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#dsd">
                      <img src={youtube} alt="youtube " />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

        </Container>
        <div className="footer_bottom">
          <p>Copyright 2017.Jagoan Premi.All rights reserved.</p>
        </div>
      </div>
    )
  }
}
export default Footer
