import React, { Component } from 'react'
import iconSubmit from "../../../assets/img/magnifier-icon.png"
import bawah from '../../../assets/img/arrow-down-blue.svg'

class FormProduk extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-sm-9">
          <div className="form-search">
            <form>
              <input type="text" className="input" placeholder="Ketik Nama/No. Polis" />
              <button>
                <img src={iconSubmit} />
              </button>
            </form>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="list-urut">
            <h3>Urutkan dari</h3>
            <button><img src={bawah} /></button>
          </div>
        </div>
      </div>
    )
  }
}
export default FormProduk
