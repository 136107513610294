import * as actionTypes from '../actions/actionTypes'

import {
  updateObject
} from '../utility'

const initialState = {
  error: null,
  loading: false,
  data: {}
}

const contactStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true
  })
}

const contactSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    loading: false
  })
}

const contactFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CONTACT_START:
    return contactStart(state, action)
  case actionTypes.CONTACT_SUCCESS:
    return contactSuccess(state, action)
  case actionTypes.CONTACT_FAIL:
    return contactFail(state, action)
  default:
    return state
  }
}

export default contactReducer
