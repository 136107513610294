import React, { Component } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// import asyncComponent from './hoc/asyncComponent';
import Login from "./Components/Login/LoginPage"
import Home from "./Components/Home/HomePage"
import ProdukContentPage from "./Components/ProdukContentPage"
import Contact from './Components/contact/Contact'
import ProfilePolis from './Components/ProfilPolis'
import Logout from './Components/Navigate/Logout'

import MenuPersonal from './Components/personal/Menu'
import PolisPersonal from './Components/personal/Polis'
import PersonalProduk from './Components/personal/Produk'
import PersonalPromotion from './Components/personal/Promosi'
import AsuransiPolisPersonal from './Components/personal/component/AsuransiPolis'

import MenuVehicle from './Components/vehicle/Menu'
import PolisVehicle from './Components/vehicle/Polis'
import ProdukVehicle from './Components/vehicle/Produk'
import PromosiVehicle from './Components/vehicle/Promosi'
import AsuransiPolisVehicle from './Components/vehicle/component/AsuransiPolis'

import MenuProperty from './Components/property/Menu'
import PolisProperty from './Components/property/Polis'
import ProdukProperty from './Components/property/Produk'
import AsuransiPolisProperty from './Components/property/component/AsuransiPolis'

import MenuTravel from "./Components/travel/Menu"
import PolisTravel from './Components/travel/Polis'
import ProdukTravel from './Components/travel/Produk'
import AsuransiPolisTravel from './Components/travel/component/AsuransiPolis'

import * as actions from './store/actions/index'

const token = localStorage.getItem('token')

// const asyncPersonal = asyncComponent(() => {
//     return import('./Components/personal/Menu');
// })

class Router extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup()
  }

  render(){

    let routes = (
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    )
    if (token) {
      routes = (
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/personal" exact="true" component={MenuPersonal} />
          <Route path="/personal/produk" component={PersonalProduk} />
          <Route path="/personal/promosi" component={PersonalPromotion} />
          <Route path="/personal/polis" exact="true" component={PolisPersonal} />
          <Route path="/personal/polis/:id" exact="true" component={ProfilePolis} />
          <Route path="/personal/polis/:id/:ids" component={AsuransiPolisPersonal} />

          <Route path="/travel" exact="true" component={MenuTravel} />
          <Route path="/travel/produk" component={ProdukTravel} />
          <Route path="/travel/polis" exact="true" component={PolisTravel} />
          <Route path="/travel/polis/:id" exact="true" component={ProfilePolis} />
          <Route path="/travel/polis/:id/:ids" component={AsuransiPolisTravel} />

          <Route path="/property" exact="true" component={MenuProperty} />
          <Route path="/property/produk" component={ProdukProperty} />
          <Route path="/property/polis" exact="true" component={PolisProperty} />
          <Route path="/property/polis/:id" exact="true" component={ProfilePolis} />
          <Route path="/property/polis/:id/:ids" component={AsuransiPolisProperty} />

          <Route path="/vehicle" exact="true" component={MenuVehicle} />
          <Route path="/vehicle/produk" exact="true" component={ProdukVehicle} />
          <Route path="/vehicle/polis" exact="true" component={PolisVehicle} />
          <Route path="/vehicle/produk/:id" component={ProdukContentPage} />
          <Route path="/vehicle/polis/:id" exact="true" component={ProfilePolis} />
          <Route path="/vehicle/polis/:id/:ids" component={AsuransiPolisVehicle} />
          <Route path="/vehicle/promosi" component={PromosiVehicle} />
                    
          <Route path="/Profil" component={ProfilePolis} />
          <Route path="/Contact" component={Contact} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      )
    }

    return (
      <div>
        {routes}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router))
