import React, { Component } from 'react'
import { connect } from 'react-redux'
import iconSubmit from "../../../assets/img/magnifier-icon.png"
import bawah from '../../../assets/img/arrow-down-blue.svg'
import {
  Popover,
  PopoverBody
} from 'reactstrap'
import ListProduk from './ListProduk'
import * as actions from '../../../store/actions/sort'

class FormProduk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popoverOpen: false,
      default: 1,
      list: this.props.sortBy,
      loader: true,
      search: ''
    }
    this.toggle = this.toggle.bind(this)
    this.onClickTerbaru = this.onClickTerbaru.bind(this)
    this.onClickTerlama = this.onClickTerlama.bind(this)
    this.onClickSearch = this.onClickSearch.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  componentDidMount(){
    this.props.onTerbaru(1, 1)
    this.setState({
      loader: false
    })
  }
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }
  onChange (e) {
    e.preventDefault()
    const {
      name,
      value
    } = e.target
    this.setState({
      [name]: value
    })
  }
  onClickTerbaru(e) {
    e.preventDefault()
    this.props.onTerbaru(1, 1)
  }
  onClickTerlama(e) {
    e.preventDefault()
    this.props.onTerlama(2, 1)
  }
  onClickSearch(e) {
    e.preventDefault()
    this.props.onSearch(this.state.search)
  }
    
  render() {
    const { search } = this.state
    if (this.state.loader) {
      return (
        <div>
          <h3>Loadingg...</h3>
        </div>
      )
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-sm-9 col-8">
              <div className="form-search">
                <form onSubmit={this.onClickSearch}>
                  <input type="text" className="input" name="search" value={search} onChange={this.onChange} placeholder="Ketik Nama...." />
                  <button>
                    <img src={iconSubmit} />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="list-urut">
                {/* <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    < DropdownToggle caret onClick={this.handleClick1} >
                        <h3>Button Dropdown</h3> <button><img src={bawah} /></button>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={this.handleClick}>Header</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown> */}
                <button id="Popover3" onClick={this.toggle}>
                  <h3>{this.props.sortName}</h3><img src={bawah} />
                </button>
                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover3" toggle={this.toggle}>
                  <PopoverBody>
                    <button onClick={this.onClickTerbaru}>Terbaru</button>
                    <button onClick={this.onClickTerlama}>Terlama</button>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
          <ListProduk list={this.props.sortBy} params={this.props.params} onclick={this.setState}/>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    sortBy : state.sort.sortBy,
    sortName : state.sort.sortName
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTerbaru: (sort, id) => dispatch(actions.dataTerbaru(sort, id)),
    onTerlama: (sort, id) => dispatch(actions.dataTerlama(sort, id)),
    onSearch: (search) => dispatch(actions.dataSearch(search))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProduk)
