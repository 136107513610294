import React, { Component } from 'react'
// import FileInput from 'react-fine-uploader/file-input'
// import FineUploaderTraditional from 'fine-uploader-wrappers'
// import { Uploader, UploadField } from '@navjobs/upload'
// import FileBase64 from 'react-file-base64'
import baseurl from '../../../helpers/constants'

import axios from "axios"
// const uploader = new FineUploaderTraditional({
//    options: {
//       request: {
//          endpoint: 'http://developer.jagoanpremi.com/v1/vendor/upload-policy/5'
//       }
//    }
// })


// const UploadFile = (
//    <FileInput multiple accept='image/*' uploader={ uploader }>
//       <span class="icon ion-upload">Choose Files</span>
//    </FileInput>
// )
var percentCompleted = "0"
class UploadFile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: '',
      fileName: '',
      nomer: "",
      statusEndorsement: [],
      cekStatus: [],
      loading: true,
      keterangan: "",
      progress: "0",
      hidden: '',
      hiddenNot: 'hidden',
      errorUpload: '',
      errorMessage: ''
    }
    this.onChangeHandle = this.onChangeHandle.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onClickKeterangan = this.onClickKeterangan.bind(this)
    this.handleHidden = this.handleHidden.bind(this)
  }
  // const log = this.props.url
  onChangeHandle(e) {
    e.preventDefault
    const target = e.target
    const name = target.name

    this.setState({
      [name]: target.value
    })
  }
  onChangeFile(e) {
    e.preventDefault()
    let files = e.target.files[0]
    var myBlob = new Blob([files], {
      type: 'plain/text'
    })
    let reader = new FileReader()
    reader.readAsDataURL(myBlob)

    reader.onloadend=() => {
      this.setState({
        file: reader.result,
        fileName: files.name
      })
    }

  }
  //ss
  componentDidMount() {
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }

    axios.get(`${baseurl.API}/v1/endorsement_status/` + this.props.url)
      .then(res => {
        const dataPolis = res.data.data
        this.setState({
          loading: false,
          cekStatus: dataPolis
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  onClickKeterangan(e) {
    e.preventDefault()
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    // let base = this.state.file(/^data:image\/(png|jpg);base64,/, "")
    let field = {
      insurance_policy_number: this.props.number,
      insurance_policy_file: this.state.file,
      description: this.state.keterangan
    }
    const config = {
      onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }
    if (this.state.file && this.state.keterangan) {
      this.setState({
        hidden: 'hidden',
        hiddenNot: ''
      })
      axios.post(`${baseurl.API}/v1/vendor/upload_policy/` + this.props.url, field, config)
        .then(res => {
          const dataPolis = res.data.data
          this.setState({
            loading: false,
            statusEndorsement: dataPolis,
            progress: percentCompleted
          })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      this.setState({
        errorMessage: 'Keterangan dan file wajib diisi.'
      })
    }
  }
  handleHidden() {
    this.setState({
      hidden: 'hidden',
      hiddenNot: ''
    })
  }
  reload(e) {
    e.preventDefault()
    location.reload()
  }
  onClick(e) {
    e.preventDefault()
    axios.defaults.headers.common = {
      'Accepts-Version': 1,
      Accept: 'application/json',
      Platform: 'website',
      'Acces-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
      token: localStorage.getItem('token')
    }
    let field = {
      insurance_policy_number: this.state.nomer,
      insurance_policy_file: this.state.file,
      description: ""
    }
    const config = {
      onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }
    if (this.state.nomer && this.state.file) {
      this.setState({
        hidden: 'hidden',
        hiddenNot: ''
      })
      axios.post(`${baseurl.API}/v1/vendor/upload_policy/` + this.props.url, field, config)
        .then(res => {
          const dataPolis = res.data.data
                    
          if (res.data.meta.status){
            this.setState({
              loading: false,
              statusEndorsement: dataPolis,
              progress: percentCompleted
            })
          } else {
            this.setState({
              loading: false,
              statusEndorsement: dataPolis,
              progress: percentCompleted,
              errorUpload: res.data.data.message
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      this.setState({
        errorMessage: 'Nomor dan file wajib diisi.'
      })
    }
  }
  render() {
    const { nomer, keterangan, errorUpload, errorMessage } = this.state
    let status = 'Menunggu Proses Upload!'
    if (this.state.progress == '100') {
      status = "Upload Selesai!"

      if (errorUpload) {
        status = errorUpload
      }
    }
    const styles = {
      bslabel: {
        display: 'inline-block',
        width: '100%',
        marginBottom: '5px',
        fontWeight: 700,
        height: '40px',
        paddingTop: '8px',
        cursor: 'pointer'
      },
      bsPenambang: {
        width: '100%',
        backgroundColor: '#d8d8d8',
        height: '10px',
        borderRadius: '90px'

      },
      bsProgressUpload: {
        width: this.state.progress + '%',
        backgroundColor: '#ff1d53',
        height: '10px',
        borderRadius: '90px',
        transition: 'all ease-in 0.1s'
      }
    }
    if (this.state.cekStatus.length === 0) {
      return (
        <div>
          <h3>No. Polis {this.state.nomer}</h3>
          <div className={this.state.hidden}>
            <form id='customForm' style={{ marginBottom: '15px' }} onSubmit={this.onClick}>
              <input type="text" name="nomer" className="keterangan" value={nomer} onChange={this.onChangeHandle} placeholder="Masukan nomer polis" />

              <input
                style={{ display: 'block' }}
                type="file"
                id="exampleInputFile"
                name="file"
                className="inputfile inputfile-6"
                accept="application/pdf"
                onChange={this.onChangeFile}
              />

              <label style={styles.bslabel} htmlFor="exampleInputFile">
                <span className="ket">{this.state.fileName}</span>
                <strong >Cari File</strong>
              </label>
              <div className="status-upload">
                {errorMessage && <h3>{errorMessage}</h3>}
                <button type="submit">Upload</button>
              </div>
            </form>
          </div>
          <div className={this.state.hiddenNot}>
            <div className="belakang" style={styles.bsPenambang}>
              <div className="progressUpload" style={styles.bsProgressUpload}></div>
            </div>
            <div className="status-upload">
              <h3>{status}</h3>
              {status =='Upload Selesai!' || errorUpload ? <button className="status-button" onClick={this.reload}>Oke</button> : ""}
            </div>
          </div>
          {/* <FileInput multiple accept='pdf/*' uploader={ uploader }>
                        <span class="icon ion-upload">Choose Files</span>
                    </FileInput> */}
          {/* <Uploader
                        request={{
                            fileName: 'file',
                            url: 'http://developer.jagoanpremi.com/v1/vendor/upload_policy/5',
                            method: 'POST',
                            fields: {
                            //extra fields to pass with the request
                                "insurance_policy_number": '',
                                "insurance_policy_file": '',
                                "description": ''
                            },

                            headers: {
                            //custom headers to send along
                            'Accepts-Version': 1,
                            'Accept': 'application/json',
                            'Platform': 'website',
                            'Acces-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json; charset=utf-8',
                            'token': localStorage.getItem('token')
                            },
                            // use credentials for cross-site requests
                            withCredentials: false,
                        }}
                        onComplete={({ response, status }) => console.log(response)}
                        //upload on file selection, otherwise use `startUpload`
                        uploadOnSelection={true}
                        >
                        {({ onFiles, progress, complete }) => (
                            <div>
                            <UploadField
                                onFiles={onFiles}
                                uploadProps={{
                                    accept: '.pdf,.doc,.docx,.txt,.rtf',
                                }}>
                                <div>
                                Click here to select a file!
                                </div>
                            </UploadField>
                            {progress ? `Progress: ${progress}` : null}
                            {complete ? 'Complete!' : null}
                            </div>
                        )}
                    </Uploader>  */}
        </div>
      )
    } else {
      return (
        <div>
          <h5>No. Endorsement: {this.state.cekStatus.length}</h5>
          <div className = {this.state.hidden}>
            <form id='customForm' style={{ marginBottom: '15px' }} onSubmit={this.onClickKeterangan}>

              {/* <FileBase64 multiple={true} onDone={this.getFiles.bind(this)} /> */}

              <input
                style={{ display: 'block' }}
                type="file"
                id="exampleInputFile"
                name="file"
                className="inputfile inputfile-6"
                accept="application/pdf"
                onChange={this.onChangeFile}
              />
              <label style={styles.bslabel} htmlFor="exampleInputFile">
                <span className="ket">{this.state.fileName}</span>
                <strong className="keter">UBAH</strong>
              </label>
              <input type="text" name="keterangan" className="keterangan" value={keterangan} onChange={this.onChangeHandle} placeholder="Keterangan" />
              <div className="status-upload">
                {errorMessage && <h3>{errorMessage}</h3>}
                <button type="submit">SAVE</button>
              </div>
            </form>
          </div>
          <div className={this.state.hiddenNot}>
            <div className="belakang" style={styles.bsPenambang}>
              <div className="progressUpload" style={styles.bsProgressUpload}></div>
            </div>
            <div className="status-upload">
              <h3>{status}</h3>
              {status =='Upload Selesai!' ? <button className="status-button" onClick={this.reload}>Oke</button> : ""}
            </div>
          </div>
        </div>
      )
    }
  }
}

export default UploadFile
