import React, { Component } from 'react'
import {
  Container
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Automotive from '../../assets/img/jp-automotive.png'
import Health from '../../assets/img/jp-health.png'
import Housing from '../../assets/img/jp-housing.png'
import Travel from '../../assets/img/jp-travel.png'
import Navigasi from '../Navigate/Navigasi'
import baseurl from '../../helpers/constants'

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menu1: {},
      menu2: {},
      menu3: {},
      menu4: {}
    }
  }
  componentDidMount() {
    fetch(`${baseurl.API}/v1/solutions`)
      .then(res => {
        return res.json()
      }).then(d => {
        this.setState({
          menu1: d[0],
          menu2: d[1],
          menu3: d[2],
          menu4: d[3]
        })
      })
  }
  render() {
    return (
      <div>
        <Navigasi />
        <Container>
          <div className="menu">
            <div className="judul">
              <h3>Pilih Kategori Produk Anda</h3>
            </div>
            <div className="item-menu">
              <Container>
                <ul className="row">
                  <li className="col-sm-3">
                    <Link to={"/personal"}>
                      <div className="item item1" onClick={this.handle1}>
                        <h3><span>Personal Solution</span></h3>
                        <img src={Health} className="health" alt="" />
                      </div>
                    </Link>
                  </li>
                  <li className="col-sm-3 section-center">
                    <Link to={"/vehicle"}>
                      <div className="item item2" onClick={this.handle2}>
                        <h3><span>Vehicle Solution</span></h3>
                        <img src={Automotive} className="automotive" alt="" />
                      </div>
                    </Link>
                  </li>
                  <li className="col-sm-3">
                    <Link to={"/property"}>
                      <div className="item item3" onClick={this.handle3}>
                        <h3><span>Property Solution</span></h3>
                        <img src={Housing} className="housing" alt="" />
                      </div>
                    </Link>
                  </li>
                  <li className="col-sm-3 section-center">
                    <Link to={"/travel"}>
                      <div className="item item4" onClick={this.handle4}>
                        <h3><span>Travel Solution</span></h3>
                        <img src={Travel} className="travel" alt="" />
                      </div>
                    </Link>
                  </li> 
                </ul>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
export default Home
